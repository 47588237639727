import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import SearchInventory from "./searchInventoryTypes";
import SearchItems from "./searchItems";
import SearchPackaging from "./searchPackagingMaterials";
import colors from "../../constants/colors";
import Swal from "sweetalert2";
import SearchInventoryLocations from "./searchInventoryLocations";
var filterInterval;

class Input extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initial,
      yieldValue: this.props.initial,
      height: 30,
      dialogVisible: false,
      reset: false,
      options: this.props.inventoryLocations ? this.props.inventoryLocations : []
    };
  }
  componentDidMount(){
    const { isInventoryTransfer, defaultLabel, company_id, isRecipe } = this.props;
    const { options } = this.state;
  }
  componentWillReceiveProps(nextProps) {
    const { defaultLabel, isRecipe, position } = this.props;
    
    if (nextProps.initial != this.props.initial) {
      this.setState({ value: nextProps.initial });
    } 
    if (nextProps.inventoryLocations != this.props.inventoryLocations) {
      var options = nextProps.inventoryLocations;
      this.setState({ options: options });
    }
    if(nextProps?.resetInventorySearch){
      if((nextProps?.resetInventorySearch?.changed != this.props?.resetInventorySearch?.changed && nextProps?.resetInventorySearch?.position == this.props?.position && this.props?.isShipmentLog && this.props?.defaultLabel == 'item')){
        this.setState({ value: nextProps.initial, reset: !this.state.reset });
      }
    }
  }
  onSelectItem(val, type) {
    const { onChange, id } = this.props;
    if(type){
      if(type == 'lot'){
        this.setState({ value: val?.lot });
      }else if(type == 'ingredient'){
      }else{
        this.setState({ value: val?.item });
      }
    }else{
      this.setState({ value: val });
    }
    onChange(id, val);
  }
  batchSizeAlert() {
    const { onChange, id } = this.props;
    const { yieldValue, value } = this.state;
    clearInterval(filterInterval);
    Swal.fire({
      text: "Are you sure you want to increase/decrease the ingredients and yield of this recipe?.",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: colors.orange,
      backdrop: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ yieldValue: value });
        onChange(id, value);
      } else {
        this.setState({ value: yieldValue });
      }
    });
  }
  validateItemStockQty(val){
    const { id, onChange, defaultLabel, components, submission, inventoryTypes, position } = this.props;
    var valid = true;
    var qtyComponent = components.find(component => component.id == id);
    if(qtyComponent){
      var lotCodeLabel = defaultLabel == "quantityFinishedGoods" ? "lotcode_finished" : "lotcode_wip";
      var nameComponent = components.find(component => component.section_id == qtyComponent.section_id && component?.defaultLabel == "item");
      var lotComponent = components.find(component => component.section_id == qtyComponent.section_id && component?.defaultLabel == lotCodeLabel);
      if(nameComponent){
        var itemName = submission[nameComponent.id][position];
        var names = submission[nameComponent.id];
        var lot = submission[lotComponent.id][position] ? submission[lotComponent.id][position] : null;
        var totalQuantity = 0;
        var selectedQuantity = 0;
        //Calculate total quantity
        var item = inventoryTypes.find(inv => inv.item == itemName);
        if(item){
          item.items.map((itm)=>{
              if(itm?.historial){
                itm.historial.map((historial)=>{
                  if(historial?.amount > 0){
                    totalQuantity += historial?.amount;
                  }
                });
              }
          });
        }
        //Calculate selected quantity
        names.map((name, idx) =>{
          if(itemName == name && idx != position){
            var selectedQty = parseFloat(submission[id][idx]);
            if(selectedQty > 0){
              selectedQuantity += selectedQty;
            }
          }
        });
        var total = parseFloat(val) + selectedQuantity;
        if(total > totalQuantity){
          valid = false;
        }
      }
    }
    if(valid){
      this.setState({ value: val });
      onChange(id, val);
    }else{
      this.alertConfirm("The amount available in this lot is insuficient", "Accept");
    }
  }
  alertConfirm(msg, conf) {
    Swal.fire({
        text: msg,
        confirmButtonText: conf,
        confirmButtonColor: '#0B4DA0',
        backdrop: false
    });
  }
  validDecimals(val) {
    var decimals = val.split('.');
    var valid = true;
    if (decimals[1]) {
        if (decimals[1]?.length > 3) {
            valid = false;
        }
    }
    if (decimals.length > 2) valid = false;  
    return valid;
  }
  checkForDefaulItems(){
    const { isRecipe, defaultLabel, submission, components, id, position } = this.props;
    var defaultItems = false;
    if(isRecipe && defaultLabel == "location"){
      var comp = components.find(component => component.id == id)
      if(comp){
        var itemComponent = components.find(component => component.section_id == comp.section_id && component.defaultLabel == "ingredient");
        if(itemComponent){
          var sub = "";
          if(position || position == 0) {
            sub = submission[itemComponent.id][position];
          } else {
            sub = submission[itemComponent.id];
          }
          sub = sub.trim();
          sub = sub.toLocaleLowerCase();
          if(sub == "water"){
            var defaultItems = true;
          }
        }
      }
    }

    return defaultItems;
  }
  render() {
    const { yieldValue, reset, options } = this.state;
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder, width, openIssue, defaultComponent, showInventorySearch, showItemsSearch, showPackagingSearch, isRecipe, defaultLabel, showFinishedGoodsSearch, showWorkInProgressSearch, itemInvoice_id, submission, position, isShipmentLog, searchClients, searchSuppliers, isInventoryTransfer, openDropDown, backgroundColor, isLast } = this.props;
    const type = defaultLabel == "lotcode_finished" ? "Finished goods" : defaultLabel == "lotcode_wip" ? "Work in progress" : "";
    var value = this.state.value;
    if(isRecipe && defaultLabel == "shelfLife" && typeof value == "object"){
      var newValue = "";
      if(value?.repeat_interval){
        newValue = value?.repeat_interval;
      }
      if(value?.repeat_interval){
        newValue += " " + value?.repeat_frequency;
      }
      value = newValue;
    }
    return (
      <>
        {!width ?
          <NewIssue
            visible={this.state.dialogVisible}
            id={id}
            label={label}
            onSubmitIssue={onSubmitIssue}
            handleClose={() => {
              this.setState({ dialogVisible: false })
            }}
            width={width}
          /> : null
        }


        <View style={styles.container, {
          paddingTop: width ? 0 : 5
        }}>
          <Text style={styles.label}>{this.props.label}</Text>
          {
            (isIssuable && isCompleted) &&
            <TouchableOpacity style={{ paddingBottom: 10, paddingTop: 5 }} onPress={() => {
              if (width) {
                openIssue();
              } else {
                this.setState({ dialogVisible: true });
              }
            }}>
              <Text style={{ color: colors.primary, fontWeight: 'bold' }}>Issue</Text>
            </TouchableOpacity>
          }
          {
            showItemsSearch &&
            <SearchItems defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val, 'ingredient')} initial={value} isShipmentLog={isShipmentLog} isInventoryTransfer={isInventoryTransfer} placeholder={"Enter text to search items"} width={width}/>
          }
          {
            showInventorySearch &&
            <SearchInventory defaultComponent={defaultComponent} onSelectItem={(val, isInvoice) => this.onSelectItem(val, 'lot')} initial={value} type={type} searchBy={'lot'} isShipmentLog={isShipmentLog} defaultLabel={defaultLabel} reset={reset} width={width}  openDropDown={openDropDown} backgroundColor={backgroundColor} isLast={isLast}/>
          }{
            showPackagingSearch &&
            <SearchPackaging defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} width={width}/>
          }
          {
            showFinishedGoodsSearch &&
            <SearchInventory defaultComponent={defaultComponent} onSelectItem={(val, isInvoice) => this.onSelectItem(val, 'item')} initial={value} type={'Finished goods'} isInventoryTransfer={isInventoryTransfer} searchBy={'item'} itemInvoice_id={itemInvoice_id} submission={submission} position={position} isShipmentLog={isShipmentLog} defaultLabel={defaultLabel} reset={reset} width={width}  openDropDown={openDropDown} backgroundColor={backgroundColor} isLast={isLast}/>
          }
          {
            showWorkInProgressSearch &&
            <SearchInventory defaultComponent={defaultComponent} onSelectItem={(val, isInvoice) => this.onSelectItem(val, 'item')} initial={value} type={'Work in progress'} isInventoryTransfer={isInventoryTransfer} searchBy={'item'} itemInvoice_id={itemInvoice_id} submission={submission} position={position} isShipmentLog={isShipmentLog} defaultLabel={defaultLabel} reset={reset} width={width}  openDropDown={openDropDown} backgroundColor={backgroundColor} isLast={isLast}/>
          }
          {
            searchClients &&
            <SearchItems defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} customers={true} placeholder={"Enter text to search customers"} width={width}/>
          }
          {
            searchSuppliers &&
            <SearchItems defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} suppliers={true} placeholder={"Enter text to search suppliers"} width={width}/>
          }
          {
            (isInventoryTransfer && (defaultLabel == "fromLocation" || defaultLabel == "toLocation")) && 
            <SearchInventoryLocations defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} options={options} placeholder={"Enter text to search locations"} width={width} isRecipe={false} openDropDown={openDropDown} backgroundColor={backgroundColor} isLast={isLast}/>
          }
          {
            (isRecipe && (defaultLabel == "location") && !this.checkForDefaulItems()) && 
            <SearchInventoryLocations defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} options={options} placeholder={"Enter text to search locations"} width={width} isRecipe={isRecipe} openDropDown={openDropDown} backgroundColor={backgroundColor} isLast={isLast}/>
          }
          {
            !showItemsSearch && !showInventorySearch && !showPackagingSearch && !showFinishedGoodsSearch && !showWorkInProgressSearch && !searchClients && !searchSuppliers && !(isInventoryTransfer && (defaultLabel == "fromLocation" || defaultLabel == "toLocation")) && !(isRecipe && (defaultLabel == "location")) &&
            <TextInput
              value={value}
              editable={!defaultComponent}
              onContentSizeChange={(event) => {
                if (event.nativeEvent.contentSize.height == 0) {
                  if (this.props.placeholder == '') {
                    this.setState({ height: 30 })
                  }
                  this.setState({ height: event.nativeEvent.contentSize.height });
                } else {
                  setTimeout(() => {
                    this.setState({ height: event.nativeEvent.contentSize.height });
                  }, 5)
                }
              }}
              style={styles.input, {
                borderColor: "#e7e7e7",
                borderRadius: 5,
                borderWidth: 1,
                color: "#424242",
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: "400",
                padding: 5,
                height: this.state.height,
                fontStyle: this.state.value ? this.state.value.length == 0 ? 'italic' : 'normal' : 'normal',
                overflow: 'visible',
              }}
              multiline={true}
              placeholder={this.props.placeholder}
              placeholderTextColor={'rgb(0, 47, 108)'}
              onChangeText={(val) => {
                if (isRecipe && defaultLabel == "yield") {
                  var formated = val.trim();
                  if (formated == '' && this.props.placeholder == '') {
                    clearInterval(filterInterval);
                    this.setState({ value: formated, height: 30 });
                  } else {
                    if (formated == '') {
                      clearInterval(filterInterval);
                      this.setState({ value: formated, yieldValue: formated });
                    } else {
                      this.setState({ value: formated }, () => {
                        clearInterval(filterInterval);
                        filterInterval = setInterval(() => this.batchSizeAlert(), 2500);
                      });
                    }

                  }
                }else {
                  if(isShipmentLog && (defaultLabel == "quantityFinishedGoods" || defaultLabel == "quantityWIP")){
                    if (val == '' && this.props.placeholder == '') {
                      this.setState({ value: val, height: 30 });
                    } else {
                      this.validateItemStockQty(val);
                    }
                  }else {
                    if(isInventoryTransfer && defaultLabel == "quantity"){
                      if(!this.validDecimals(val))return;
                    }
  
                    if (val == '' && this.props.placeholder == '') {
                      this.setState({ value: val, height: 30 });
                    } else {
                      this.setState({ value: val });
                    }
                    onChange(id, val);
                  }
                }
              }}
            />
          }

        </View>
      </>

    );
  }
}
export default connect((state, ownProps = {}) => ({
  company_id: state.user.get("company_id"),
}), (dispatch, props) => ({
  dispatch: dispatch,
}))(Input);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    // paddingBottom: 10,
    // paddingHorizontal: 10,
    paddingTop: 5,
    flex: 1,
  },
  input: {
    borderColor: "#e7e7e7",
    borderRadius: 5,
    borderWidth: 1,
    color: "#424242",
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "400",
    paddingLeft: 10,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',

  },
});
