import { createAction } from "redux-actions";
import {Alert} from 'react-native';
import Swal from "sweetalert2";

import {
  INVALID_REGISTER_HIDE,
  SET_REGISTER_LOADING,
  SET_PAYMENT_COMPLETED,
} from "../constants/actions";

import { requestRegister as _register } from "../api";
import alerts from '../components/components/alert';
import colors from "../constants/colors";
export function register(user_data) {
    /**
     * The Tag definition.
     *
     * @param {Object} user_data - Contains all the personal data of the new user
     */    
    return (dispatch, getState) => {
      dispatch(createAction(SET_REGISTER_LOADING)(true));
      setTimeout(()=>{
        dispatch(createAction(SET_REGISTER_LOADING)(false));
      },3000);
      const promise = _register(dispatch, getState, user_data)
        .then((response) => {
          dispatch(createAction(INVALID_REGISTER_HIDE)(true));
          
          return response;
        })
        .catch((error) => {          
          dispatch(createAction(INVALID_REGISTER_HIDE)(false));
          Swal.fire({
            text: "There is an error creating your account. Please try again later or try a different email.",
            confirmButtonText: "OK",
            confirmButtonColor: colors.orange,
            backdrop: false
          });
          return error;
        });
    };
  }
  export function resetRegister() {
    return (dispatch, getState) => {
      dispatch(createAction(INVALID_REGISTER_HIDE)(false));
      return;

    }
  }

export function setPaymentCompleted(paid){
  return (dispatch, getState) => {
    dispatch(createAction(SET_PAYMENT_COMPLETED)(paid));
  }
}
