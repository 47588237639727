import React, { Component } from 'react';
import { View, StyleSheet, Text, ScrollView, TouchableOpacity, Image, ActivityIndicator, InteractionManager, Dimensions } from 'react-native';
import Modal from 'react-native-modalbox';
import { connect } from 'react-redux';
import { logDialogClose } from '../../actions/submissions';
import SubmissionsTable from '../widgets/table';
import { startingSync, sync } from '../../actions/submissions';

class LogDialog extends Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (!this.props.dialogOpen && newProps.dialogOpen) {
      this.refs.modal.open();
    }
  }

  onClose() {
    this.props.dispatch(logDialogClose());
  }

  retry() {
    this.props.dispatch(startingSync());
    setTimeout(() => {
      this.props.dispatch(sync());
    }, 5 * 1000); // 5 sec
  }

	render() {
    /*const { log,
      syncState } = this.props; */
    var {height, width} = Dimensions.get('window');

		return <Modal style={[styles.modal, styles.modal3, {height: height-220}]} ref={"modal"} swipeToClose={false} position={"center"} onClosed={this.onClose.bind(this)}>
	    {  /*syncState != 'startingSync' && syncState != 'syncInProgress' ?
        <TouchableOpacity style={styles.retry} onPress={this.retry.bind(this)}>
          <Text>RETRY</Text>
        </TouchableOpacity> : null */
      }
      <View style={{alignSelf: 'flex-start', paddingLeft: 25 }}>
        <Text style={styles.submissionHeader}>Submissions Log</Text>
      </View>
      <ScrollView style={styles.logContainer}>
      {
       /* log.reverse().map((e, i) => {
          return <View key={i} style={[styles.logRow, e.type=='start' ? {marginBottom: 15} : null]}>
            <Text style={{flex: 0.3}}>{e.date}</Text>
            <Text style={[{flex: 0.7},e.type=='success'?{color:'#7ED321'}:(e.type=='error'?{color:'#D0021B'}:null)]}>{e.log}</Text>
          </View>;
        }) */
      }
      </ScrollView>
	  </Modal>;
	}
}

export default connect(state => {
	return {
   /* dialogOpen: state.sync.get('dialogOpen'),
    log: state.sync.get('log'),
    syncState: state.sync.get('state'),*/
	};
})(LogDialog);

const styles = StyleSheet.create({
  wordTwo: {
    fontWeight: '300',
  },
  dialogTitle: {
    color: '#444',
    fontSize: 32,
    textAlign: 'center',
    backgroundColor: 'transparent',
    fontWeight: '600',
    fontFamily: 'Roboto',
  },
  modal: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modal3: {
    width: 600
  },
  dialogTableR: {
    textAlign: 'center',
    marginLeft: 5,
    fontSize: 10,
  },
  submissionHeader: {
    marginTop: 25,
  	fontWeight: '600',
  	fontSize: 14,
  },
  logRow: {
    flex: 1,
    flexDirection:'row',
  },
  logContainer: {
    flex: 1,
    alignSelf: 'stretch',
    margin: 25,
  },
  retry: {
    position: 'absolute',
    top: 25,
    right: 25,
  },
});