import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import { searchItems } from "../../actions/records";
import { getMyCustomers, getMySuppliers } from "../../actions/submissions";
import DropDown from "../widgets/dropdown";
import Swal from "sweetalert2";

class SearchItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.initial ? props.initial : "",
            supplierSelected: props.initial ? props.initial : "",
            height: 30,
            items: [],
            filteredSuppliers: [],
            wasEdited: false,
            openList: false,
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.initial != this.props.initial){
            this.setState({ value: nextProps.initial });
        }
    }
    componentDidMount(){
        const { suppliers, company_id } = this.props;
        // if(suppliers){
        //     this.props.getMySuppliers(company_id, 1, { ignoreFilters: true }, (res) => {
        //         if(res?.suppliers?.length > 0){
        //             var newSuppliers = this.formatSuppliers(res.suppliers);
        //             this.setState({ items: newSuppliers, filteredSuppliers: newSuppliers });
        //         }
        //     });
        // }
    }
    formatSuppliers(suppliers){
        var formatedSuppliers = suppliers.map((sup,idx)=>{
            return {
                id: idx+1,
                value: sup.name
            }
        });

        return formatedSuppliers;
    }
    searchItems(val) {
        const { onSelectItem, customers, company_id, isShipmentLog, suppliers, isInventoryTransfer } = this.props;
        const { items } = this.state;
        if(val){
            if(customers){
                this.props.getMyCustomers({ page: 1, name: val, company_id }, (res) => {
                    if(res?.customers?.length > 0){
                        this.setState({ items: res?.customers });
                    }else{
                        this.setState({ items: [] });
                        onSelectItem(val);
                    }
                });
            }else if(suppliers){
                this.props.getMySuppliers(company_id, 1, { name: val }, (res) => {
                    if(res?.suppliers?.length > 0){
                        this.setState({ items: res?.suppliers });
                    }else{
                        this.setState({ items: [] });
                        onSelectItem(val);
                    }
                });
            }else{
                var itemsData = { page: 1, name: val };
                if(isShipmentLog)itemsData.fromShipmentLog = true;
                this.props.searchItems(itemsData, (res) => {
                    if(res?.items?.length > 0){
                        this.setState({ items: res?.items });
                    }else{
                        this.setState({ items: [] });
                        if(!isShipmentLog)onSelectItem(val);
                    }
                });
            }
        }else{
            this.setState({ items: [] });
            if(isInventoryTransfer)onSelectItem(val);
        } 
    }
    alertConfirm(msg, conf) {
        Swal.fire({
            text: msg,
            confirmButtonText: conf,
            confirmButtonColor: '#0B4DA0',
            backdrop: false
        });
    }
    render(){
        const { value, items, wasEdited, filteredSuppliers, openList } = this.state;
        const { defaultComponent, onSelectItem, placeholder, customers, isShipmentLog, suppliers, isInventoryTransfer, width } = this.props;
        return <View>
            <TextInput
                value={value}
                editable={!defaultComponent}
                onContentSizeChange={(event) => {
                    if (event.nativeEvent.contentSize.height == 0) {
                        if (this.props.placeholder == '') {
                            this.setState({ height: 30 })
                        }
                        this.setState({ height: event.nativeEvent.contentSize.height });
                    } else {
                        setTimeout(() => {
                            this.setState({ height: event.nativeEvent.contentSize.height });
                        }, 5)
                    }
                }}
                style={styles.input, {
                    borderColor: "#e7e7e7",
                    borderRadius: 5,
                    borderWidth: 1,
                    color: "#424242",
                    fontFamily: "Roboto",
                    fontSize: 20,
                    fontWeight: "400",
                    padding: 5,
                    height: this.state.height,
                    fontStyle: this.state.value ? this.state.value.length == 0 ? 'italic' : 'normal' : 'normal',
                    overflow: 'visible',
                }}
                multiline={true}
                placeholder={placeholder}
                placeholderTextColor={'rgb(0, 47, 108)'}
                onChangeText={(val) => {
                    if (val == '' && this.props.placeholder == '') {
                        this.setState({ value: val, height: 30, wasEdited: true });

                    } else {
                        this.setState({ value: val, wasEdited: true });
                    }
                    this.searchItems(val);

                }}
            />
            {
                items?.length > 0 &&
                    items.map(item =>{
                        return <div style={{...styles.row, gridTemplateColumns: customers ? "95% 5%" : width == "mini" ? "80% 20%" : "90% 10%"}}>
                            <div>
                                {customers ? item?.name : suppliers ? item?.name : item?.itemName}
                            </div>
                            <div>
                                <button style={styles.rowBtn} onClick={() => {
                                    if(isInventoryTransfer){
                                        if(item?.items?.length <= 0 && typeof item?.items == "object"){
                                            this.alertConfirm(item.item + " does not have lot codes available to transfer.", "Accept");
                                        }else{
                                            onSelectItem(item?.itemName)
                                            this.setState({ items: [],value: item?.itemName, wasEdited: false});
                                        }
                                    }else if(customers || suppliers){
                                        onSelectItem(item?.name)
                                        this.setState({ items: [],value: item?.name, wasEdited: false});
                                    }else if(isShipmentLog){
                                        var newItem = item;
                                        newItem.lotSelected = ["default_lot"]; 
                                        onSelectItem(item, true);
                                        this.setState({ items: [], wasEdited: false});
                                    }else{
                                        onSelectItem(item?.itemName)
                                        this.setState({ items: [],value: item?.itemName, wasEdited: false});
                                    }
                                    
                                }}>+</button>
                            </div>
                            
                        </div>
                    })
            }
            {/* {
                suppliers && items?.length > 0 &&
                <DropDown
                  initialOption={ typeof filteredSuppliers.find(x => x.value == this.state.supplierSelected) === 'undefined'  ? "" : {value:  this.state.supplierSelected }}
                  updated={this.state.supplierSelected}
                  options={filteredSuppliers}
                  defaultComponent={defaultComponent}
                  openList={openList}
                  onSelect={(option) => {
                      onSelectItem(option?.value)
                  }}
                />
            } */}
            {
                value && items?.length <= 0 && wasEdited && <View>
                    <Text>No {customers ? 'customers' : 'items'} were found</Text>
                </View>
            }

        </View>
    }
}

export default connect((state, ownProps = {}) => ({
    isLoading: state.forms.getIn(["form", "status"]) === "loading",
    company_id: state.user.get("company_id"),
}), (dispatch, props) => ({
    searchItems: (formid, saveAsTemplate, saveAsForm, callback) =>
        dispatch(searchItems(formid, saveAsTemplate, saveAsForm, callback)),
    getMyCustomers: (data, callback) =>
        dispatch(getMyCustomers(data, callback)), 
    getMySuppliers: (id, page, filter, callback) =>
        dispatch(getMySuppliers(id, page, filter, callback)),
}))(SearchItems);

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        paddingTop: 5,
        flex: 1,
    },
    input: {
        borderColor: "#e7e7e7",
        borderRadius: 5,
        borderWidth: 1,
        color: "#424242",
        fontFamily: "Roboto",
        fontSize: 22,
        fontWeight: "400",
        paddingLeft: 10,
    },
    label: {
        fontSize: 20,
        color: '#939598',
        fontWeight: '300',
        fontFamily: 'Roboto',

    },
    row:{
        display: "grid",
        width: "100%",
        fontFamily: "Roboto",
        fontSize: 18
    },
    rowBtn:{
        color: 'rgb(244, 152, 30)',
        border: '2px solid',
        borderRadius: 4
    }
});