import React, { useEffect, useState } from "react";
import { View, TextInput, Text, TouchableOpacity, ActivityIndicator } from "react-native";
import NewIssue from "../../dialogs/newIssue";
import { callAssistantApi, requestCompletionApi } from "../../../api";
import Swal from "sweetalert2";
import colors from "../../../constants/colors";
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from "react-redux";
import styles from "./chatAI.styles";
import * as ImagePicker from "expo-image-picker";
import { getOpenAIContent } from "./utils";
import { saveFormFormAIPrompt, createFormFromAgentFile } from "../../../actions/tasks";
import uuid from "uuid";

const ChatAI = ({ initial, id, onChange, width, label, onSubmitIssue, isCompleted, defaultComponent, placeholder, promptMessages, formName }) => {

  const dispatch = useDispatch();
  const authToken = useSelector(state => state.user.get("authToken"));
  
  const [state, setState] = useState({
    value: { response: initial?.response ? initial?.response : "", question: "" },
    question: "",
    height: 30,
    openAIResponse: "",
    heightAI: 100,
    loading: false,
    editable: initial?.response ? true : false,
    chatDialog: [],
    responses: [],
    imagePickerResult: null,
    imgName: "",
  });

  useEffect( () => {
    setState({
      ...state,
      chatDialog: initial?.chatDialog || [],
    })
  }, [initial]);

  useEffect( () => {
  }, [promptMessages]);
  
  const [indHeight, setIndHeight] = useState(50);

  const sendPromptMessage = async (promptM) => {
    const { value, question, chatDialog, imagePickerResult } = state;
    const dialog = {};
    const propsPromptMessages = promptMessages;
    if (question != "" || ((propsPromptMessages?.sendingType == "record" || propsPromptMessages?.sendingType == "packagingMaterialsRecord") && imagePickerResult)) {
      setState({ ...state, loading: true })
      dialog.question = question;
      const content = await getOpenAIContent(question, promptM?.imageUploadEnabled, imagePickerResult, authToken);
      let temperature = promptM?.temperature ? parseFloat(promptM.temperature) : 0.9;
      let promptMessages = [];
      
      if(promptM?.initialContext)
        promptMessages.push({ role: "system", content: `${promptM?.initialContext} ${promptM?.additionalContext || ""}` });
      chatDialog.forEach(locDialog => {
        promptMessages.push({ role: "user", content: locDialog.question });
        promptMessages.push({ role: "assistant", content: locDialog.answer });
      });
      promptMessages.push({ role: "user", content })

      dispatch(requestCompletionApi({
          messages: promptMessages,
          temperature,
        },
        (response) => {
          const msj = response.choices[0].message.content;
          const raw_json = msj;
          let newResponses = [
            {
              data: JSON.stringify({
                choices: [
                  {
                    message: {
                      content: raw_json.replace(/^```json|```$/g, '')
                    }
                  }
                ]
              })
            }
          ];
          dialog.answer = msj;
          if((propsPromptMessages?.sendingType == "record" || propsPromptMessages?.sendingType == "packagingMaterialsRecord")){
            setState({
              ...state, 
              value: { question: value?.question, response: msj },
              question: "",
              height: 30,
              loading: false,
              editable: true,
              imagePickerResult: null,
              imgName: "",
            });
          }else{
            setState({ 
              ...state, 
              value: { question: value?.question, response: msj },
              question: "",
              height: 30,
              chatDialog: [...chatDialog, dialog],
              loading: false,
              editable: true,
              imagePickerResult: null,
              imgName: "",
            });
          }
          onChange(id, [...chatDialog, dialog], "chatDialog");
          if(promptM)
            switch (promptM?.sendingType) {
              case "text":
                break;
              case "csv":
                newResponses.at(0).type = 'csv'; 
                makeResponsesActions(newResponses);
                break;
              case "record":
              case "packagingMaterialsRecord":
                newResponses.at(0).type = promptM.sendingType; 
                makeResponsesActions(newResponses);
                break;
              default:
                newResponses.at(0).type = 'form'; 
                makeResponsesActions(newResponses)
                break;
            }
        }
      ));
    }
  }
  const makeResponsesActions = (responses) => {
    if (responses.length > 0) {
      responses.map((response) => {
        var data = response.data;
        
        switch (response.type) {
          case "csv":
            try {
              const json = JSON.parse(data);
              const blob = new Blob([json.choices[0].message.content], { type: 'data:text/csv;charset=utf-8,' })
              const blobURL = window.URL.createObjectURL(blob)
              const anchor = document.createElement('a')
              anchor.download = "response.csv"
              anchor.href = blobURL
              anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
                ':'
              )
              anchor.click()
              setTimeout(() => {
                URL.revokeObjectURL(blobURL)
              }, 100)
            } catch (error) {
              console.log(error);
              setState({ ...state, loading: false })
              showErrorAlert("Sorry, it looks like open AI couldn´t form a proper structure to create a csv file, please try again");
            }
            break;
          case "record":
          case "packagingMaterialsRecord":
            try {
              const json = JSON.parse(data);
              const recordData = JSON.parse(json.choices[0].message.content);
              const Uuid = uuid.v1();

              dispatch(createFormFromAgentFile({data: recordData, uuid: Uuid, name: formName, type: response.type}, () => {
                showErrorAlert("A new record was created succesfully");
              }));
            } catch (error) {
              console.log(error);
              setState({ ...state, loading: false })
              showErrorAlert("Sorry, it looks like open AI couldn´t form a proper structure to create a record, please try again");
            }
            break;
          default:
            try {
              const json = JSON.parse(data);
              if (json.error) {
                showErrorAlert("Sorry, it looks like the request is too large, for now open AI has a maximum context length of 4097 tokens, please select a smaller one and try again.");
              } else {
                createForm(JSON.parse(json.choices[0].message.content));
              }
            } catch (error) {
              setState({ ...state, loading: false })
              showErrorAlert("Sorry, it looks like open AI couldn´t form a proper structure to create a new form, please try again");
            }
        }
      });
    }
  }
  const createForm = (form) => {
    var valid = validateForm(form);
    if (valid) {
      var formatedForm = formatForm(form);
      delete formatedForm._id;
      formatedForm.roles = promptMessages?.roles;
      formatedForm.locations = promptMessages?.locations;
      formatedForm.teams = promptMessages?.teams;
      formatedForm.version = promptMessages?.version;
      dispatch(saveFormFormAIPrompt(formatedForm, () => {
        showErrorAlert("A new form " + form.title + " was created succesfully");
      }))
    } else
      showErrorAlert("Sorry, it looks like the information given by open AI is incomplete to create a new form, please try again");

  }
  const formatForm = (form) => {
    var newSections = form.sections;
    var newForm = form;
    var index = 1;
    form.sections.map((section, idx) => {
      newSections[idx].id = index.toString();
      index++;
      section.components.map((component, componentIdx) => {
        newSections[idx].components[componentIdx].id = index.toString();
        index++;
      });
    });
    newForm.sections = newSections;

    return newForm;
  }
  const validateForm = (formatedData) => {
    var valid = true;
    if (formatedData.sections) {
      formatedData.sections.map((section) => {
        if (section.components) {
          section.components.map((component) => {
            if (!component.label) valid = false;
          });
        } else {
          valid = false;
        }
      });
    } else {
      valid = false;
    }

    return valid;
  }
  const showErrorAlert = (msg) => {
    Swal.fire({
      text: msg,
      confirmButtonText: "OK",
      confirmButtonColor: colors.primary,
      backdrop: false
    });
  }

  const extractToCSV = (text) => {
    const parts = text.split('~~');

    const tables = parts.filter(part => part.trim() !== '' && part.includes(','));

    const csvTables = tables.map(table => {
      const lines = table.split('\n').map(line => line.trim());

      const filteredLines = lines.filter(line => line !== '');

      return filteredLines.join('\n');
    });

    return csvTables.join('\n\n');
  }

  const handlePressedBtn = async (defaultComponent, promptMessages, dispatch) => {
    const { question, chatDialog, thread_id, imagePickerResult } = state;
    const dialog = {
      question,
      answer: "",
    }
    if (question.includes("@assistant") || promptMessages?.assistantId) {
      const content = await getOpenAIContent(question, promptMessages?.imageUploadEnabled, imagePickerResult, authToken);
      setState({ ...state, loading: true, openAIResponse: "" })
      dispatch(callAssistantApi({
        content,
        thread_id,
        ...(promptMessages?.assistantId && { assistant_id: promptMessages.assistantId }),
        ...(promptMessages?.messages && { messages: promptMessages.messages }),
        ...(promptMessages?.temperature && { temperature: promptMessages.temperature }),
      }, ({ answer, thread_id, ...rest }) => {
        dialog.answer = answer;
        if (answer.includes("~~")) {
          const csvContent = extractToCSV(answer);
          dialog.table = csvContent;
        }
        setState({
          ...state,
          question: "",
          value: { question, response: answer },
          loading: false,
          thread_id,
          imagePickerResult: null,
          imgName: "",
          chatDialog: [...chatDialog, dialog],
        });
        // PLEASE DON'T MODIFY THE STATE AFTER THIS STATEMENT AS IT WILL CAUSE CONTEXT PROBLEMS
        // IF YOU NEED TO MODIFY THE STATE, PLEASE DO IT IN THE STATEMENT ABOVE
        setIndHeight(50);
        onChange(null, [...chatDialog, dialog], "chatDialog");
        const raw_json = answer;
        let newResponses = [
          {
            data: JSON.stringify({
              choices: [
                {
                  message: {
                    content: raw_json.replace(/^```json|```$/g, '')
                  }
                }
              ]
            })
          }
        ];
        switch (promptMessages?.sendingType) {
          case "text":
            break;
          case "csv":
            newResponses.at(0).type = 'csv'; 
            makeResponsesActions(newResponses);
            break;
          case "record":
          case "packagingMaterialsRecord":
            newResponses.at(0).type = promptMessages.sendingType; 
            makeResponsesActions(newResponses);
            break;
          default:
            newResponses.at(0).type = 'form'; 
            makeResponsesActions(newResponses)
            break;
        }
        
      }));
    }
    else if (!defaultComponent) {
      sendPromptMessage(promptMessages);
    }
  }

  return (
    <>
      {!width ?
        <NewIssue
          visible={state.dialogVisible}
          id={id}
          label={label}
          onSubmitIssue={onSubmitIssue}
          handleClose={() => setState({ ...state, dialogVisible: false })}
          width={width}
        /> : null
      }

      <>
        {state.chatDialog?.map((dialog) => (
          <View>
            <View style={{ flexDirection: "row" }}>
              <Icon style={{ flex: 0.1 }} name="person" size={24} color="#229BFA" />
              <Text style={{ flex: 0.9, color: "#229BFA", fontSize: 18, fontWeight: 'bold' }}>You</Text>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              <View style={{ flex: 0.1 }}></View>
              <Text style={{ flex: 0.9, fontSize: 16, fontWeight: 'bold', maxHeight: 300, overflow: 'scroll' }}>{dialog.question}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Icon style={{ flex: 0.1 }} name="at-outline" size={24} color="#0453A9" />
              <Text style={{ flex: 0.9, color: "#0453A9", fontSize: 18, fontWeight: 'bold' }}>Ai Chat Bot</Text>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              <View style={{ flex: 0.1 }}></View>
              <View style={{ flex: 0.9, fontSize: 16, fontWeight: 'bold', maxHeight: 300, overflow: 'scroll' }}>
                {dialog.answer.split("\n").map((line, index) => <Text>{line}</Text>)}
              </View>
            </View>
          </View>
        ))}

        {!isCompleted &&
          <View style={styles.container}>
            { promptMessages?.imageUploadEnabled &&
              <View style={styles.attachments}>
                <TouchableOpacity
                  disabled={isCompleted}
                  onPress={async () => {
                    
                    let result = await ImagePicker.launchImageLibraryAsync({
                      mediaTypes: ImagePicker.MediaTypeOptions.Images,
                      allowsEditing: true,
                      aspect: [4, 3],
                      quality: 0.6,
                      base64:true 
                    });
                    let format = result.assets.at(0).uri.match(/\/(.*?)\;/).at(1);
                    setState({ ...state, imagePickerResult: result, imgName: `attachment.${format}`});
                  }}
                >
                  <Icon name="attach-outline" style={styles.icon} size={24}/>
                </TouchableOpacity>
                {!!state.imgName && <Text style={{ color: colors.primary, fontSize: 8 }}>{state.imgName}</Text>}
              </View>
            }
            <TextInput
              style={[styles.textInput, {height: indHeight}]}
              value={state.question}
              editable={!defaultComponent}
              onContentSizeChange={(event) => {
                setIndHeight(event.nativeEvent.contentSize.height);
              }}
              multiline={true}
              placeholder={state.chatDialog.length > 0 ? "anything else?" : placeholder ? placeholder : "Type a question for the AI Assistant"}
              placeholderTextColor={'rgb(0, 47, 108)'}
              onKeyPress={({ nativeEvent: { key: keyValue } }) => {
                if(keyValue === 'Enter'){
                  handlePressedBtn(defaultComponent, promptMessages, dispatch);
                }
              }}
              onChangeText={(val) => {
                /* const sendQuestion = () => {
                  const lastChar = val.charAt(val.length - 1);
                  if (lastChar === '\n') handlePressedBtn(defaultComponent, promptMessages, dispatch);
                } */
                if (val == '' && placeholder == '') {
                  setState({ ...state, question: val.replace(/\n/g, ""), height: 30 });
                  //sendQuestion();

                } else {
                  setState({ ...state, question: val.replace(/\n/g, "") });
                  //sendQuestion();
                }
              }}
            />
             <TouchableOpacity
              disabled={isCompleted}
              onPress={() => handlePressedBtn(defaultComponent, promptMessages, dispatch)}
            >
              {state.loading ?
                <ActivityIndicator size="large" color={colors.primary} /> :
                <Icon name="arrow-forward-circle" style={styles.icon} size={24} />
              }
            </TouchableOpacity>
          </View>
        }
      </>
    </>
  );
}

export default ChatAI;
