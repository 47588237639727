import React, { Component } from "react";
import { TouchableOpacity, View, Text, StyleSheet } from "react-native";
import colors from "../../constants/colors";
import Chip from "./chip";
import ChipOutline from "./chipOutline";
import PropTypes from "prop-types";
import Icon from "react-native-vector-icons/MaterialIcons";
import { connect } from "react-redux";
//import { Actions } from "react-native-router-flux";
import Animations from '../animations';
import Moment from 'moment';

class TaskCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracted: true,
    };

  }

  openClose(){
    const {contracted} = this.state;
    this.setState({contracted: !contracted});    
  }

  showChips(){
    const {contracted} = this.state;

    return (
      <View style={styles.chipsContainer}>

      { !contracted ? 
        <View>
          <Text style={styles.dateLabel}>Due date</Text>
          <Text style={styles.dateText}>{this.props.timeLeft}</Text>
        </View> : null
      }      

      {this.props.frequency != "" && (!this.props.hasChipOutline || !contracted) && (
        <View style={{ flexDirection: "row",width: 100, height: 50 }}>
          <Chip              
            label={this.translateFrequency(this.props.frequency)}
            type={this.props.chipType}            
          ></Chip>
        </View>
      )}           
     
        <View style={{ flexDirection: "row", marginTop: 10 }}>
          {!contracted ? 
              <TouchableOpacity
                style={styles.buttonArchiveClear}
                onPress={this.props.archiveAction}
              >
                <Text style={styles.buttonArchiveText}>Clear</Text>
              </TouchableOpacity>
          :null}
          {!contracted && this.props.confirmOtherOverdues?
                <TouchableOpacity
                style={styles.buttonArchive}
                onPress={this.props.archiveOverduesAction}
              >
                <Text style={styles.buttonArchiveText}>Clear All Overdue Tasks</Text>
              </TouchableOpacity>:null}
          {this.props.hasChipOutline && (
            <ChipOutline
              label={this.props.outlineText}
              type={this.props.chipTypeOutlined}
            ></ChipOutline>
          )}
        </View>
       
      </View>
    );

  }

  translateStatus(name){
    switch(name){
        case "all" : return "All";
        case "in_progress" : return "In Progress";
        case "edit_required" : return "Edit Required";
        case "complete" : return "Complete";
        case "approved" : return "Approved";
        case "archived" : return "Archived";
        case "pending" : return "Pending";
        default : "In Progress";            
      }
  }  

  getTagStyle(name){
    switch(name){
        case "in_progress" : return styles.inProgressTag;
        case "edit_required" : return styles.editRequiredTag;                 
        default : return styles.inProgressTag;
    }
  }

  translateFrequency(val) {
    switch (val) {
      case "Month":
        return "Monthly";
        break;
      case "Year":
        return "Annually";
        break;
      case "Day":
        return "Daily";
        break;
      case "Week":
        return "Weekly";
        break;
      default:
        return "Unknown";
    }
  }

  getStatus(submission){
    if(submission){
      const {status} = submission;
      return <Text style={this.getTagStyle(status)}>{this.translateStatus(status)}</Text>
    }else{
      return <Text style={styles.inProgressTag}>Pending</Text>
    }
  }

  render() {

    const {contracted} = this.state;
    const {isLogGroup,isSaving,taskSelected, tasksStatus,taskStatus, date, membersTxt} = this.props;
    //const taskSaving = taskSelected._id == this.props._id && (isSaving.get('status') == 'saving' || tasksStatus == 'loading');
    const taskSaving =  isSaving.get('status') == 'saving' || tasksStatus == 'loading';
    const submissionStatus = (typeof this.props.submission != 'undefined') ? this.props.submission.status : 'not_set';
    const submissionMembersTxt = membersTxt ? membersTxt : "";
    //const locDate = new Date(Date.UTC(date)).toLocaleDateString('en-US');
    var dt = new Date();
    var tz = dt.getTimezoneOffset();
    const locDate = Moment(date, "YYYY-MM-DD")
        .add(tz/60, 'hours')
        .format('YYYY-MM-DD');
    //const locDate = date;
    return (
      <TouchableOpacity
        disabled={taskSaving}
        style={{
          padding: 10,
          marginTop: 5,
          marginBottom: 0,
          backgroundColor: "white",
          flexDirection: contracted? "row": "column",              
        }}              
        onPress={()=>this.openClose()}
      >
        <View style={styles.taskTitle}>
          <View style={styles.labelContainer}>
            {
              submissionStatus == 'edit_required' ?
                  <Text>{this.getStatus(this.props.submission)} {submissionMembersTxt ? this.props?.submission?.status != undefined ? `- ${submissionMembersTxt}` : `${submissionMembersTxt}` : ""}</Text>
                : !isLogGroup && <Text style={this.getTagStyle(taskStatus)} >{this.translateStatus(taskStatus)} {submissionMembersTxt ? this.props?.submission?.status != undefined ? `- ${submissionMembersTxt}` : `${submissionMembersTxt}` : ""}</Text>
            }
            {
              isLogGroup && <Text style={styles.groupTag}>Log</Text> 

            }
            <Text style={[styles.taskLabel, styles.taskNormal]}>
              {this.props.taskName}
            </Text>
            <Text>
              Start date: {locDate}
            </Text>
          </View>
        </View>
        <View style={{flexDirection: "row", flexGrow: 1}}>
        <View style={contracted?styles.taskChip: {flexGrow:1}}>
           {this.showChips()}
        </View>
        <TouchableOpacity style={styles.taskAction} onPress={!taskSaving?this.props.action:null}>
                { taskSaving ?
                  <Animations animationfile='loading-dots-gray' />:
                  <View>
                    <Icon name="chevron-right" size={30} color={colors.blue_gray} />
                  </View>
                }
                
        </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  }
}
export default connect(
  (state) => {
    return {
      isSaving: state.forms.getIn(['form'],'status'),
      taskSelected: state.tasks.get('taskSelected'),
      tasksStatus: state.tasks.get('status'),
    };
  },
  (dispatch, props) => {
    return {};
  }
)(TaskCard);

TaskCard.propTypes = {
  taskName: PropTypes.string.isRequired,
  date: PropTypes.string,
  action: PropTypes.func.isRequired,
  frequency: PropTypes.string.isRequired,
  hideArrow: PropTypes.bool,
  chipType: PropTypes.string.isRequired,
  hasChipOutline: PropTypes.bool,
  outlineText: PropTypes.string,
};
const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  groupTag: {
    fontWeight: "700",
    color: colors.subtitle,
  },
  inProgressTag:{
    fontWeight: "700",
    color: colors.subtitle,
  },
  editRequiredTag:{
    fontWeight: "800",
    color: "#E34537",
  },
  taskTitle: {
    flex:1,
    flexGrow: 50,
    flexDirection: "row",
    alignItems: "center",
  },
  taskChip: {
    width: 100,    
    paddingHorizontal: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  taskAction:{
    width: 30,  
    flexDirection: "row",
    alignItems: "center",
  },
  loadingAnimation: { 
    height: 50,
    width: 50,
  },
  labelContainer: {
    flexGrow: 4,
    flexShrink: 1,
  },
  chipsContainer:{
    flexGrow:1,
  },
  dateLabel: {
    color: colors.description,
    fontSize: 16,
    paddingVertical: 10,
  },
  dateText: {
    color: colors.description,
    fontFamily: "Roboto-Medium",
    fontSize: 18,
    paddingBottom: 10,
  },
  formsLabel: {
    color: colors.blue_gray,
    fontFamily: "Roboto-Italic",
    fontSize: 18,
    paddingBottom: 15,
    paddingTop: 15,
  },
  submissionsLabel: {
    color: colors.blue_gray,
    fontFamily: "Roboto",
    fontSize: 20,
    paddingBottom: 15,
    paddingTop: 15,
  },
  taskLabel: {
    wordBreak: 'break-word',
    flexShrink: 1,
    fontFamily: "Roboto-Bold",
    fontSize: 18,
    paddingBottom: 5,
    paddingTop: 5,
  },
  taskPrimary: {
    color: colors.primary,
  },
  taskNormal: {
    color: colors.secondary,
  },
  taskError: {
    color: colors.error,
  },
  buttonArchive: {
    height: 60,
    width: 120,
    justifyContent: "center",
    borderRadius: 8,
    alignItems: "center",
    backgroundColor: colors.clear,
    marginRight: 20,
  },
  buttonArchiveClear: {
    height: 60,
    width: 60,
    justifyContent: "center",
    borderRadius: 8,
    alignItems: "center",
    backgroundColor: colors.clear,
    marginRight: 20,
  },
  frequencyChip: {
    height: 60,
    width: 120,
    justifyContent: "center",
    borderRadius: 8,
    alignItems: "center",
    backgroundColor: colors.primary,
    marginRight: 20,
  },
  buttonArchiveText: {
    color: colors.userCommandText,
    margin: 5,
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
});
