import { handleActions } from "redux-actions";
import Immutable from "immutable";
import { GET_LOG_GROUPS, SET_LOG_GROUP_SELECTED, SET_IS_LOG_GROUP, GET_LOG_GROUPS_SUBMISSIONS, SET_LOG_GROUPS_SUBMISSIONS, SET_LOG_GROUPS_SUBMISSIONS_FALSE, SHOULD_RELOAD_LGSUBMISSIONS, GET_GROUP_DETAILS } from "../constants/actions";
//import { Actions } from "react-native-router-flux";

import StorageService from "../services/storage";

const initialState = {
    logGroups: [],
    logGroupSelected: {},
    isLogGroup: false,
    logGroupSubmissions:{},
    shouldReload: false, 
    submissionIndicator:false
};

export default handleActions({
        [GET_LOG_GROUPS]: (state, action) => {
            //   StorageService.set("tasks", action.payload);
            return state.set("logGroups", action.payload);
        },
        [SET_LOG_GROUP_SELECTED]: (state, action) => {
            return state.set("logGroupSelected", action.payload);
        },
        [SET_IS_LOG_GROUP]: (state, action) => {
            return state.set("isLogGroup", action.payload);
        },
        [GET_LOG_GROUPS_SUBMISSIONS]: (state, action) => {
            return state.set("logGroupSubmissions", action.payload);
        },
        [SET_LOG_GROUPS_SUBMISSIONS_FALSE]: (state, action) => {
            return state.set("submissionIndicator", false);
        },
        [SET_LOG_GROUPS_SUBMISSIONS]: (state, action) => {
            return state.set("submissionIndicator", true);
        },
        [SHOULD_RELOAD_LGSUBMISSIONS]: (state, action) => {
            return state.set("shouldReload", action.payload);
        },
        [GET_GROUP_DETAILS]: (state, action) => {
            return state.set("logGroupSelected", action.payload);
        },
    },
    Immutable.fromJS(initialState)
);