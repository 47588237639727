import React, { Component } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import PropTypes from "prop-types";
import colors from "../../constants/colors";

class FormCard extends Component {
  render() {
    let imageURL =
      this.props.formImageUri != null
        ? "https://iqops-qa.iqkitchen.biz/" + this.props.formImageUri
        : "https://via.placeholder.com/400";
    let background = this.props.isSelected ? colors.primary : colors.formItem;
    return (
      <TouchableOpacity
        style={[styles.whiteSection, { backgroundColor: background }]}
        onPress={this.props.action}
      >
        <View style={{ flex: 4, marginTop: 10, marginBottom: 10 }}>
          <Text
            style={{
              fontFamily: "Roboto-Bold",
              fontSize: 20,
              margin: 5,
              paddingLeft: 10,
              color: this.props.isSelected ? "white" : colors.formItemText,
            }}
          >
            {this.props.formTitle}
          </Text>          
          {/* <Text
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                margin: 5,
                paddingLeft: 10,
                paddingRight: 20,
                color: this.props.isSelected ? "white" : colors.blue_gray,
              }}
            >
              {this.props.formDescription}
            </Text> */}
        </View>
        {this.props.formImageUri != null && (
          <View
            style={{
              alignItems: "center",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              marginRight: 10,
            }}
          >
            <Image
              style={styles.formImage}
              source={{
                uri: imageURL,
              }}
            />
          </View>
        )}
      </TouchableOpacity>
    );
  }
}
export default FormCard;

FormCard.propTypes = {
  formTitle: PropTypes.string,
  formSections: PropTypes.number,
  formDescription: PropTypes.string,
  formImageUri: PropTypes.string,
  isSelected: PropTypes.bool,
  action: PropTypes.func,
};

const styles = StyleSheet.create({
  whiteSection: {
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 8,
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    padding: 10,
    shadowColor: "black",
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 2,
    elevation: 2,
  },
  formImage: {
    flex: 1,
    height: 100,
    width: 100,
    resizeMode: "contain",
  },
});
