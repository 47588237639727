import React, { Component } from "react";
import { View, ScrollView, Text, StyleSheet, TextInput, Dimensions } from "react-native";
import { getAiCategoriesAndPromptsAction, editCategoriesVisibilityAction, getPromptCategoriesAction, setFormsFromAPI } from "../actions/tasks";
import { connect } from "react-redux";
import colors from "../constants/colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";
const deviceWidth = Dimensions.get('screen').width > 1000;

class SubmenuPrompts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            filteredItems: [],
            height: 30,
            changed: false,
            searchString: ""
        };
    }
    componentDidMount() {
        this.props.getAiCategoriesAndPrompts((res) => {
            if (res?.categories) {
                this.setState({ items: res?.categories, filteredItems: res?.categories });
            }
        });
    }
    changeVisibility(idx, val) {
        const { items } = this.state;
        const { company_id } = this.props;
        const newItems = items;
        if (newItems[idx]?.privacity == "public") {
            var companiesVisibility = newItems[idx].companiesVisibility ? newItems[idx].companiesVisibility : {};
            if (companiesVisibility[company_id]) {
                companiesVisibility[company_id] = false;
            } else {
                companiesVisibility[company_id] = true;
            }
            newItems[idx].companiesVisibility = companiesVisibility;
        } else {
            newItems[idx].visible = !newItems[idx].visible;
        }
        this.setState({ items: newItems, changed: true });

        this.props.editCategoriesVisibility({ categories: newItems }, () => this.props.getPromptCategoriesAction());
    }
    componentWillUnmount() {
        if (this.state.changed) {
            this.props.setFormsFromAPI();
        }
    }
    filterForms(search) {
        const { items } = this.state;

        var prompts = items.filter(item => item.type == "category" || item.label.toLowerCase().includes(search.toLowerCase()));
        this.setState({ filteredItems: prompts });
    }
    render() {
        const { items, searchString, filteredItems } = this.state;
        const { company_id } = this.props;
        return (<View style={deviceWidth ? styles.viewContainerMax1 : styles.viewContainerMax2}>
            <ScrollView>
                <View>
                    <View style={{
                        // height: 40,
                        borderRadius: 20,
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 10,
                        marginBottom: 10
                    }}>
                        <MaterialCommunityIcons
                            style={{ paddingLeft: 20 }}
                            name="magnify"
                            size={30}
                            color={colors.search_bar}
                        />
                        <TextInput
                            onContentSizeChange={(event) => {
                                console.log(event.nativeEvent.contentSize.height);

                                if (event.nativeEvent.contentSize.height == 0) {
                                    if (this.props.placeholder == '') {
                                        this.setState({ height: 30 })
                                    }
                                    this.setState({ height: event.nativeEvent.contentSize.height });
                                } else {
                                    setTimeout(() => {
                                        this.setState({ height: event.nativeEvent.contentSize.height });
                                    }, 5)
                                }
                            }}
                            style={
                                {
                                    borderRadius: 20,
                                    width: '100%',
                                    height: this.state.height,
                                    paddingTop: 10,
                                    paddingRight: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 20,
                                    fontSize: 18,
                                    color: colors.search_bar,
                                }
                            }
                            multiline={true}
                            value={searchString}
                            placeholder="Search for a form"
                            onChangeText={(search) => {
                                this.setState({ searchString: search }, () => this.filterForms(search))
                            }}
                            underlineColorAndroid="transparent"
                        />
                    </View>
                    <Text style={styles.subtitle}>Add or Remove categories or Features</Text>
                    {
                        filteredItems.map((item, idx) => {
                            if (item.type == "category") {
                                return <View key={idx} style={styles.itemContainer}>
                                    <View style={{ ...styles.itemLabel, flex: 1, backgroundColor: colors.primary }}><Text style={{ ...styles.itemLabelText, color: '#fff' }}>{item?.label}</Text></View>
                                </View>
                            } else {
                                if (item.privacity == "public") {
                                    var checked = item?.companiesVisibility ? item?.companiesVisibility[company_id] ? item?.companiesVisibility[company_id] : false : false;
                                    return <View key={idx} style={{ ...styles.itemContainer, paddingLeft: 10 }}>
                                        <View style={styles.itemLabel}><Text style={styles.itemLabelText}>{item?.label}</Text></View>
                                        <View >
                                            <label class="switch">
                                                <input type="checkbox" checked={checked} onChange={(val) => this.changeVisibility(idx, val.target.value)} />
                                                <span
                                                    class="slider round"
                                                ></span>
                                            </label>
                                        </View>
                                    </View>
                                } else {
                                    return <View key={idx} style={{ ...styles.itemContainer, paddingLeft: 10 }}>
                                        <View style={styles.itemLabel}><Text style={styles.itemLabelText}>{item?.label}</Text></View>
                                        <View >
                                            <label class="switch">
                                                <input type="checkbox" checked={item.visible == true} onChange={(val) => this.changeVisibility(idx, val.target.value)} />
                                                <span
                                                    class="slider round"
                                                ></span>
                                            </label>
                                        </View>
                                    </View>
                                }
                            }
                        })
                    }
                    {/* <Text>
                        Submenu
                    </Text> */}
                </View>
            </ScrollView>
        </View>)
    }
}
export default connect(
    (state) => {
        return {
            company_id: state.user.get("company_id"),
        };
    },
    (dispatch, props) => {
        return {
            getAiCategoriesAndPrompts: (callback) => dispatch(getAiCategoriesAndPromptsAction(callback)),
            editCategoriesVisibility: (data, callback) => dispatch(editCategoriesVisibilityAction(data, callback)),
            getPromptCategoriesAction: (callback) => dispatch(getPromptCategoriesAction(callback)),
            setFormsFromAPI: () => {
                dispatch(setFormsFromAPI());
            },
        };
    }
)(SubmenuPrompts);

const styles = StyleSheet.create({
    viewContainerMax1: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 20,
        marginTop: 10,
        minWidth: 560,
        maxWidth: 800,
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        marginHorizontal: 'auto',
    },
    viewContainerMax2: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 20,
        marginTop: 10,
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        marginHorizontal: 'auto',
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold'
    },
    itemLabel: {
        flex: 6,
        border: `3px ${colors.primary} solid`,
        borderColor: colors.primary,
        borderRadius: 5,
        padding: 5,
        margin: 5
    },
    itemLabelText: {
        fontSize: 18,
        fontWeight: 600
    },
    itemSwitch: {
        flex: 1,
        borderColor: colors.primary,
        borderRadius: 5,
        margin: 5
    },
    subtitle: {
        textAlign: 'center',
        marginBottom: 20
    },
    checked: {
        ":before": {
            backgroundColor: colors.primary
        }
    },
    noChecked: {
        ":before": {
            backgroundColor: '#ccc'
        }
    }
});