import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet, TextInput, ActivityIndicator, TouchableOpacity } from 'react-native'
import { useSelector, useDispatch } from "react-redux";
import colors from "../constants/colors";
import moment from "moment-timezone";
import { setIsLogGroup, getGroupDetail, getLogGroupsAction } from "../actions/loggroups";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { setIsSubmission } from "../actions/submissions";
import {
  setTaskSelected,
  createNewTask,
} from "../actions/tasks";
import storage from "../services/storage";
import "./styles.css";
import { viewContainer } from "./functions";
import FloatingMenu from './widgets/floatingMenu';
import {BRANDING} from "@env";

const newLogTask = (props) => {
  const dispatch = useDispatch();
  const loggroups = useSelector(state => state.loggroups.get('logGroups'))
  const loadingLogGroups = useSelector(state => state.loggroups.get('loadingLogGroups'))

  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [viewContainerStyle, setViewContainerStyle] = useState({});

  useEffect(() => {
    function handleResize() {
      console.log("WIDTH: ", width);
    }
    window.addEventListener('resize', handleResize);

    return _ => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);
  

  useEffect(() => {  
    getLogs();
  }, [loading])

  
  const getLogs = () => {
    var promise = Promise.resolve(loggroups);
    promise.then(function (loggroups) {
      setLogs(loggroups);
      setFilteredLogs( loggroups );
    });
  }
  let goToDetailTask = (item) => {
    // clearInterval(_interval);
    if (typeof item.log_group_id !== "undefined" && item.log_group_id != "" && item.log_group_id != null) {
      dispatch(setTaskSelected({ ...item }));
      dispatch(getGroupDetail(item.log_group_id));
      dispatch(setIsSubmission(false));
      dispatch(setIsLogGroup(true));
      setLoading(false);
      props.navigation.navigate('GroupView');
    }
  };
  const filterLogs = (search) => {
    let clone = [];
    clone = logs.filter((item) =>
    //console.log(item)
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    //clone = logs;
    setFilteredLogs( clone );
    //console.log(filteredLogs)
  };
  return (
    <View style={{
      flexDirection: "row"
    }}>
      {BRANDING=='iqops'?<FloatingMenu />:null}
      <View style={{ flex: 1 }} />
      <View className={"viewContainer"} style={ viewContainer(width, styles) } >
        <View style={{ display: "flex", justifyContent: "center" }}>
          <Text style={styles.title}>Add a new Log</Text>
          <Text style={styles.text}>
            Select the log group to create the log from.
          </Text>
        </View>
        <View style={{
          height: 40,
          borderRadius: 20,
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10
        }}>
          <MaterialCommunityIcons
            style={{ paddingLeft: 40 }}
            name="magnify"
            size={30}
            color={colors.search_bar}
          /> 
          <TextInput
            style={
              styles.textInput
            }
            placeholder="Search for a log group"
            onChangeText={(searchString) => filterLogs(searchString)}
            underlineColorAndroid="transparent"
          />
          
        </View>
        <View>
        <TouchableOpacity 
            onPress={() => { 
              setLoading(true);
              dispatch(getLogGroupsAction());
              setTimeout(() =>{
                getLogs();
                setLoading(false)
              }, 1000)
            }}
            style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", height: 30 }}>
            <Text style={{
              fontFamily: "Roboto-Bold",
              fontSize: 12,
              color: colors.subtitle,
            }}>Reload Log Groups</Text>
            <MaterialCommunityIcons
              name="refresh"
              style={{
                fontSize: 15,
                paddingHorizontal: 10,
                color: "gray",
              }}
            />
          </TouchableOpacity>
        </View>
        {loading ?
          <View
            style={{
              flex: 1,
              padding: 40,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color={colors.primary} />
          </View> :
          <View style={{ backgroundColor: colors.formsContainer, margin: 10, borderRadius: 20 }}>
            {
              logs.length > 0 ?
              filteredLogs.map(log => {
                return (
                  <View style={styles.itemCard}
                    key={log.id}
                    onClick={() => {
                      setLoading(true);
                      let start = moment.tz(new Date(), "UTC").format("YYYY-MM-DD HH:mm");
                      let object = {
                        name: log.name,
                        description: log.name,
                        log_group_id: log.id,
                        start_date: start,
                        // locations: log.locations,
                        // roles: log.roles,
                        expiration_date: moment(start).add(1, "day").format("YYYY-MM-DD HH:mm"),
                        repeat_frequency: "",
                      };
                      dispatch(createNewTask(object));
                      setTimeout(() => {
                        goToDetailTask(storage.get('NewTask'));
                      }, 1500);
                    }}
                  >
                    <Text style={{
                      fontFamily: "Roboto-Bold",
                      fontSize: 20,
                      margin: 5,
                      paddingLeft: 10,
                      color: colors.formItemText,
                    }}>{log.name}</Text>
                  </View>
                )
              }):
              <View style={{
                flex: 1,
                padding: 40,
                justifyContent: "center",
                alignItems: "center",
              }}>
                <Text style={{
                  fontFamily: "Roboto-Bold",
                  fontSize: 20,
                  margin: 5,
                  paddingLeft: 10,
                  color: colors.error,
                }}>Upgrade to paid version to use this function</Text>
              </View>
            }
          </View>
        }
      </View>
      <View style={{ flex: 1 }} />
    </View>
  )
}
export default newLogTask
const styles = StyleSheet.create({
  title: {
    paddingLeft: 10,
    textAlign: "center",
    fontSize: 22,
    fontFamily: "Roboto-Medium",
    marginTop: 20,
    marginBottom: 10,
    color: colors.title
    
  },
  text: {
    color: colors.black,
    textAlign: "center",
    fontSize: 16,
    paddingLeft: 10,
    paddingBottom: 10,
    marginTop: 10,
    fontFamily: "Roboto",
    color: colors.tweny_black,
  },
  textInput: {
    borderRadius: 20,
    flex: 1,
    maxWidth: 450,
    height: 30,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    fontSize: 18,
    color: colors.search_bar,
  },
  itemCard: { 
    backgroundColor: colors.formItem,
    borderRadius: 10,
    margin: 10,
    padding: 15,
    display: "flex",
  },
  viewContainerMax: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 600,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  viewContainerMax1: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 350,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 300,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
})