import React, { Component } from "react";
import {
  Alert,
  View,
  Text,
  StyleSheet,
  Dimensions,
  TextInput,
  ScrollView,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import TaskCard from "./widgets/taskCard";
import colors from "../constants/colors";
import { navigateForm } from "../actions/menu";
import Swal from "sweetalert2";

class TaskDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskSelected: this.props.taskSelected,
      data: [
        {
          name: "Hannah Johanson",
          date: new Date(),
        },
        {
          name: "Hannah Johanson",
          date: new Date(),
        },
        {
          name: "Hannah Johanson",
          date: new Date(),
        },
        {
          name: "Hannah Johanson",
          date: new Date(),
        },
      ],
      search: "",
    };
  }

  goToDetailTask = () => {
    console.log("Detail");
  };

  renderSubmissions = () => {
    let submissions = [];
    this.state.data.forEach((item, index) => {
      submissions.push(
        <View style={styles.whiteSection} key={index + ""}>
          <Text
            style={{
              fontFamily: "Roboto",
              paddingTop: 10,
              paddingBottom: 10,
              color: colors.gray_darken_3,
              fontSize: 26,
            }}
          >
            {item.name}
          </Text>
          <Text
            style={{
              fontFamily: "Roboto-Italic",
              paddingTop: 10,
              paddingBottom: 10,
              color: colors.gray_darken_3,
              fontSize: 22,
            }}
          >
            {item.date.toString()}
          </Text>
        </View>
      );
    });
    return submissions;
  };

  render() {
    let chipColor = "";
    switch (this.state.taskSelected.repeat_frequency) {
      case "Day":
        chipColor = "primary";
        break;
      case "Week":
        chipColor = "accent";
        break;
      case "Month":
        chipColor = "cyan";
        break;
      case "Annual":
        chipColor = "ambar";
        break;
      default:
        chipColor = "primary";
    }
    return (
      <View style={styles.container}>
        <ScrollView style={{ flex: 4 }}>
          <Text style={styles.title}>Report detail</Text>
          <TaskCard
            taskName={this.state.taskSelected.name}
            date={this.state.taskSelected.start_date}
            frequency={this.state.taskSelected.repeat_frequency}
            chipType={chipColor}
            hideArrow
            action={() =>
              this.props.selectForm(
                this.props.taskSelected.forms_id,
                this.props.taskSelected.name,
                this.props.previousSubmissions,
                this.props.taskSelected._id
              )
            }
          />
          {/* <Text style={[styles.title, { marginTop: 20 }]}>Submissions</Text>
          {this.renderSubmissions()} */}
        </ScrollView>
        <View
          style={{ flex: 1, justifyContent: "flex-end", alignItems: "center" }}
        >
          <TouchableOpacity
            style={styles.buttonCreate}
            onPress={() =>
              this.props.selectForm(
                this.props.taskSelected.forms_id,
                this.props.taskSelected.name,
                this.props.previousSubmissions,
                this.props.taskSelected._id
              )
            }
          >
            <Text style={styles.buttonCreateText}>ADD NEW ENTRY</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default connect(
  (state) => {
    return {
      taskSelected: state.tasks.get("taskSelected"),
      token: state.user.get("authToken"),
      previousSubmissions: state.submissions.get("previousSubmissions"),
    };
  },
  (dispatch, props) => {
    return {
      selectForm: (id, name, previousSubmissions, task_id) => {
        if (!previousSubmissions[task_id]) {
          dispatch(navigateForm(id, name, true, task_id));
          return;
        }

        Swal.fire({
          title: "Open Report",
          text: "Would you like to start a new report, or reopen the previous report?",
          confirmButtonText: "New Report",
          denyButtonText: "Reopen Previous Report",
          showDenyButton: true,
          confirmButtonColor: colors.primary,
          backdrop: false

        }).then((result) => {
          if(result.isConfirmed) {
            dispatch(navigateForm(id, name, true));
          } else if(result.isDenied) {
            dispatch(navigateForm(id, name, false));
          }
        });
      },
    };
  }
)(TaskDetail);

const styles = StyleSheet.create({
  buttonCreate: {
    height: 70,
    width: Dimensions.get("window").width,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary,
    marginTop: 40,
    shadowColor: "black",
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: -4,
    },
    shadowRadius: 6,
  },
  buttonCreateText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
  },
  container: {
    backgroundColor: "#F8F8F8",
    flex: 1,
    paddingTop: 90,
  },
  fabButton: {
    alignItems: "center",
    backgroundColor: colors.ambar,
    borderRadius: 40,
    bottom: 20,
    height: 80,
    justifyContent: "center",
    position: "absolute",
    right: 20,
    width: 80,
    zIndex: 10,
    shadowColor: "black",
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    elevation: 4,
  },
  filterIcon: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
  },
  filterButton: {
    flex: 1,
    paddingLeft: 20,
    fontSize: 22,
    backgroundColor: "#fff",
    color: "#90A4AE",
  },
  input: {
    flex: 1,
    height: 70,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    fontSize: 22,
    backgroundColor: "#fff",
    color: "#90A4AE",
  },

  searchSection: {
    height: 70,
    flexDirection: "row",
    backgroundColor: "#fff",
    alignItems: "center",
    marginTop: 20,
  },
  searchContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchIcon: {
    paddingLeft: 20,
  },
  title: {
    fontFamily: "Roboto-Italic",
    fontSize: 28,
    paddingLeft: 20,
  },
  whiteSection: {
    backgroundColor: "#fff",
    marginTop: 20,
    padding: 20,
  },
});
