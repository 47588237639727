import { handleActions } from "redux-actions";
import Immutable from "immutable";
import {
  SUBMISSION_ADD,
  LOAD_STORAGE,
  SUBMISSION_SUCCESS,
  SUBMISSION_START,
  SUBMISSION_ERROR,
  SET_SUBMISSION_SELECTED,
  SET_IS_SUBMISSION,
  LOADING_ISSUE,
  SET_SUBMISSION_SELECTED_TASK_ID
} from "../constants/actions";
/*import { Actions, ActionConst } from "react-native-router-flux";*/
import { Alert } from "react-native";
import StorageService from "../services/storage";

const defaultState = {
  submissions: [],
  previousSubmissions: {},
  submissionSelected: {},
  loadingIssue: null,
  isSubmission: false,
};

export default handleActions(
  {
    [LOAD_STORAGE]: (state) => {
      const submissions = StorageService.getSubmissions();

      // Reset
      submissions.map((o) => {
        if (o.status == "started") {
          o.status = "pending";
        }
        return o;
      });

      StorageService.setSubmissions(submissions);

      return state
        .set("submissions", submissions)
        .set("previousSubmissions", StorageService.getPreviousSubmissions());
    },
    [SUBMISSION_ADD]: (state, action) => {
      const submission = action.payload;
      const id = submission.task_id;
      console.log("submission inside submission add");
      let submissions = StorageService.getSubmissions() || [];
      submissions.push(submission);
      StorageService.setSubmissions(submissions);

      StorageService.setPreviousSubmission(id, submission);   
      let previousSubmissions =  JSON.parse(JSON.stringify(StorageService.getPreviousSubmissions()));
      
      return state
        .set("submissions", submissions)
        .set("previousSubmissions", previousSubmissions)
        /*.set("previousSubmissions", StorageService.getPreviousSubmissions())*/;
    },
    [SUBMISSION_START]: (state, action) => {
      const uuid = action.payload.uuid;
      let submissions = StorageService.getSubmissions() || [];

      submissions.map((o) => {
        if (o.uuid == uuid) {
          o.status = "started";
        }
        return o;
      });

      StorageService.setSubmissions(submissions);

      return state.set("submissions", submissions);
    },
    [SUBMISSION_ERROR]: (state, action) => {
      const uuid = action.payload.uuid;
      let submissions = StorageService.getSubmissions() || [];

      submissions.map((o) => {
        if (o.uuid == uuid) {
          o.status = "pending";
        }
        return o;
      });

      StorageService.setSubmissions(submissions);

      return state.set("submissions", submissions);
    },
    [SUBMISSION_SUCCESS]: (state, action) => {
      const uuid = action.payload.uuid;
      let submissions = StorageService.getSubmissions() || [];

      submissions.map((o) => {
        if (o.uuid == uuid) {
          delete o.submission;
          delete o.choicelist;
          o.status = "success";
        }
        return o;
      });

      StorageService.setSubmissions(submissions);

      return state.set("submissions", submissions);
    },
    [SET_SUBMISSION_SELECTED]: (state, action) => {
      return state.set("submissionSelected", action.payload);
    },
    [SET_SUBMISSION_SELECTED_TASK_ID]: (state, action) => {
      var submissionSelected = state.get("submissionSelected") ? JSON.parse(JSON.stringify(state.get("submissionSelected"))) : {};
      submissionSelected.task_id = action.payload;
      return state.set("submissionSelected", submissionSelected);
    },
    [SET_IS_SUBMISSION]: (state, action) => {
      console.log('VALUE'+JSON.stringify(action))
      return state.set("isSubmission", action.payload);
    },
    [LOADING_ISSUE]: (state, action) => {
      return state.set("loadingIssue", action.payload);
    },
  },
  Immutable.fromJS(defaultState)
);
