// Change colors for each BRANDING, ghcs o iqops
import { BRANDING } from "@env";
export default {
  primary: BRANDING == "ghcs" ? "#002f6c" : "#0b4dad",
  primary_light: "#20B2AA33",
  secondary: "#263238",
  secondary2: BRANDING == "ghcs" ? "#263238" : "#4eafa9",
  tertiary: BRANDING == "ghcs" ? "#002f6c" : "#4eafa9",
  accent: "#0084BF",
  error: BRANDING == "ghcs" ? "#DB3A3A" : "#cc0000",
  gray: "#F8F8F8",
  pink: "#EC407A",
  ambar: "#FBC02D",
  cyan: "#80DEEA",
  yellow: "#fac818",  
  orange: BRANDING == "ghcs" ? "#f4981e" : "#419bff",
  gray_darken_2: "#CFD8DC",
  gray_darken_3: "#455A64",  
  blue_gray: BRANDING == "ghcs" ? "#002f6c" : "#0b4dad",
  blue_gray2: BRANDING == "ghcs" ? "#002f6c" : "#16bc4d",
  success: "#56bf72",
  black: "#000",  
  title: BRANDING == "ghcs" ? "#000000" : "#0b4dad",
  tweny_black: "#cccccc",
  subtitle: BRANDING == "ghcs" ? "#a7aaaa" : "gray",
  description: "#a7aaaa",
  disabled: "#a7aaaa",
  userCommand: BRANDING == "ghcs" ? "#f4981e" : "#4eafa9",
  userCommandText: "#ffffff",
  commandSecondary: "#CFD8DC",
  commandSecondaryText: BRANDING == "ghcs" ? "#002f6c" : "white",
  loginContainer: BRANDING == "ghcs" ? "white" : "#0b4dad",
  loginInput: "white", 
  blue: BRANDING == "ghcs" ? "#f4981e" : "#419bff",
  checkBox: BRANDING == "ghcs" ? "#f4981e" : "white",
  checkBox2: BRANDING == "ghcs" ? "#f4981e" : "#0b4dad",
  label_blue: BRANDING == "ghcs" ? "#002f6c" : "white",
  logout:  BRANDING == "ghcs" ? "white" : "#419bff",
  search_bar: BRANDING == "ghcs" ? "#90A4AE" : "#419bff",
  clear: BRANDING == "ghcs" ? "#f4981e" : "#838484",
  formsContainer: BRANDING == "ghcs" ? "#CFD8DC" : "white",
  formItem: BRANDING == "ghcs" ? "white" : "#e8eff6",
  formItemText: BRANDING == "ghcs" ? "#002f6c" : "#000000",
  back: BRANDING == "ghcs" ? "white" : "#419bff",
  wizard: BRANDING == "ghcs" ? "green" : "#16bc4d",
  add: BRANDING == "ghcs" ? "none" : "#16bc4d",
  quit: BRANDING == "ghcs" ? "none" : "#cc0000",
  save: BRANDING == "ghcs" ? "#CFD8DC" : "#0b4dad",
  send: BRANDING == "ghcs" ? "#f4981e" : "#16bc4d",
  editrequired: BRANDING == "ghcs" ? "#f4981e" : "#0b4dad",
  approve: BRANDING == "ghcs" ? "#CFD8DC" : "#419bff", 
  remove: BRANDING == "ghcs" ? "#f4981e" : "#cc0000",
  close: BRANDING == "ghcs" ? "red" : "#838484",
  closeText: BRANDING == "ghcs" ? "#000" : "#838484",
  closeWizard: BRANDING == "ghcs" ? "#f4981e" : "#838484",
  edit: BRANDING == "ghcs" ? "#f4981e" : "#002f6c",
  validate: BRANDING == "ghcs" ? "#f4981e" : "#16bc4d",
  saveWizard: BRANDING == "ghcs" ? "#f4981e" : "#0b4dad",
  in_progress: BRANDING == "ghcs" ? "lightgray" : "#002f6c",
  edit_required: BRANDING == "ghcs" ? "lightgray" : "#0b4dad",
  complete: BRANDING == "ghcs" ? "lightgray" : "#16bc4d",
  approved: BRANDING == "ghcs" ? "lightblue" : "#419bff",
  archived: BRANDING == "ghcs" ? "lightgreen" : "#838484",
  tasks_number: BRANDING == "ghcs" ? "#a7aaaa" : "#419bff",
  remember_me: BRANDING == "ghcs" ? "#002f6c" : "white",
  filter: BRANDING == "ghcs" ? "#fff" : "#e8eff6",
  filterByType: BRANDING == "ghcs" ? "#CFD8DC" : "#e8eff6",
  BRANDING: BRANDING,
};

