import React, { useCallback, useRef, useState } from 'react'
import {
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import colors from "../constants/colors";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import "./recordPaginator.css";

export const RecordPaginator = ({
  currentPage,
  pageSize,
  navigationHandler,
  tasksCount,
  filter,
  resultsPerPage = 10,
}) => {

  const listRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const nPages = tasksCount % resultsPerPage === 0 ? Math.trunc(tasksCount / pageSize) : Math.trunc(tasksCount / pageSize) + 1;
  const pagesArray = Array.from({ length: nPages }, (_, i) => i + 1);
  const onPressHandler = useCallback((page) => navigationHandler({ page, ...(filter != 'all' && { filter }) }), [navigationHandler, filter]);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - listRef.current.offsetLeft);
    setScrollLeft(listRef.current.scrollLeft);
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - listRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scroll speed
    listRef.current.scrollLeft = scrollLeft - walk;
  };

  return <View style={{ flex: 1, flexDirection: 'row', backgroundColor: colors.primary, padding: 10, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
    {currentPage != 1 &&
      <TouchableOpacity onPress={() => onPressHandler(currentPage - 1)} >
        <Icon name="menu-left-outline" style={{ color: '#fff', fontSize: 38 }} />
      </TouchableOpacity>}
    <div
      ref={listRef}
      className="scrollable-list"
      onMouseDown={startDragging}
      onMouseMove={onDrag}
      onMouseUp={stopDragging}
      onMouseLeave={stopDragging}
    >
      {pagesArray?.map((i) => {
        return(
        <TouchableOpacity key={`indxNum_${i}`} onPress={() => onPressHandler(i)}>
          <Text style={{
            color: '#fff',
            fontSize: 18,
            marginHorizontal: 5,
            fontWeight: i === currentPage ? 'bold' : 'normal'
          }}>
            {i}
          </Text>
        </TouchableOpacity>
        )
      })}
    </div>
    {currentPage != nPages &&
      <TouchableOpacity onPress={() => onPressHandler(currentPage + 1)} >
        <Icon name="menu-right-outline" style={{ color: '#fff', fontSize: 38 }} />
      </TouchableOpacity>}
  </View>
};