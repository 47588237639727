import React, { Component } from "react";
import { StyleSheet, View, Text, Platform, TouchableOpacity, TouchableHighlight } from "react-native";
import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';
import moment from "moment-timezone";
import NewIssue from "../dialogs/newIssue";
import colors from "../../constants/colors";
import "../../../App.module.css";

const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();

export default class DateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.getDate(this.props.initial),
      showDate: false,
      showTime: false,
      showDatePicker: false,
      currentMode: "date",
      value2: new Date(),
      showModal: false,
      dialogVisible: false,

    };
  }
  getDate(newDate) {
    const { secondDatePicker } = this.props;
    let d = moment.utc(newDate).tz(this.props.companyTimezone)
    if (d && d.format("YYYY-MM-DDTHH:mm") != "Invalid date") {
        d = d.format("YYYY-MM-DDTHH:mm");
        return d;
    }
    let date = new Date();
    return date;
  }
  getDate2(newDate) {
    const { secondDatePicker } = this.props;
    let d = moment.utc(newDate).tz(this.props.companyTimezone)
    if (d) {
      if (secondDatePicker) {
        d = d.format("YYYY-MM-DDTHH:mm");
        return d;
      } else {
        d = d.format("YYYY-MM-DD HH:mm");
        let date = new Date(d);
        return date;
      }
    }

    let date = new Date();
    return date;
  }
  parseDate(value) {
    if (value) {
      this.setState({ value: moment(value).format("YYYY-MM-DD hh:mm A ").toString() });
      return moment(value).format("YYYY-MM-DD hh:mm A ").toString();
    }
    else
      return moment().format("YYYY-MM-DD hh:mm A").toString();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({value: this.getDate(nextProps.initial)});
    }
  }
  // renderDateTimePicker(){
  //   const { label, onChange, id, companyTimezone } = this.props;
  //   return <DateTimePicker
  //            value={this.state.value}
  //            mode={this.state.currentMode} 
  //            display="default"
  //            placeholder={this.props.placeholder}           
  //            onChange={(event, date) => {       
  //                if(date){                       
  //                  if(this.state.currentMode == "time" && Platform.OS !== "ios" ){                 
  //                    this.setState({ value: date, currentMode: "datetime" });                      
  //                  }else{
  //                    if(Platform.OS !== "ios"){
  //                      this.setState({value: date, currentMode: "time",showDatePicker: false});                    
  //                    }else{
  //                      this.setState({value: date, currentMode: "datetime",showDatePicker: false});                    
  //                    } 
  //                  }
  //                  let r = moment(date).format("YYYY-MM-DD HH:mm");
  //                  let d = moment.tz(r, companyTimezone);
  //                  onChange(id, d.tz('UTC').format("YYYY-MM-DD HH:mm"))
  //                }else{
  //                  this.setState({ currentMode: "datetime",showDatePicker: false });                      
  //                } 
  //            }}
  //          />;
  // }

  render() {
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder, companyTimezone, initial, width, openIssue, defaultComponent, secondDatePicker } = this.props;
    return (
      <>
        {!width ?
          <NewIssue
            visible={this.state.dialogVisible}
            id={id}
            label={label}
            onSubmitIssue={onSubmitIssue}
            handleClose={() =>{
              this.setState({ dialogVisible: false });
            }}
            width={width}
          /> : null
        }

        <View style={{
          ...styles.container,
          paddingTop: width ? 0 : 5
        }}>
          {
            (label || placeholder) &&
            <Text style={styles.label}>
              {label}
              <Text style={styles.placeholder}>
                {placeholder}
              </Text>
            </Text>
          }


          {
            (isIssuable && isCompleted) &&
            <TouchableOpacity style={{ paddingBottom: 10, paddingTop: 5 }} onPress={() => {
              if (width) {
                openIssue();
              } else {
                this.setState({ dialogVisible: true });
              }
            }}>
              <Text style={{ color: colors.primary, fontWeight: 'bold' }}>Issue</Text>
            </TouchableOpacity>
          }
              <input 
                id={"datepicker"+id}
                type={"datetime-local"}
                disabled={defaultComponent}
                value={this.state.value}
                style={{...styles.button,     
                  backgroundColor: colors.primary,
                  textAlign: 'center',
                  fontSize: 16,
                  color: colors.userCommandText,
                  fontFamily: 'Roboto'
                }}
                onChange={date => {
                  let r = moment(date.target.value).format("YYYY-MM-DD HH:mm");
                  let d = moment.tz(r, companyTimezone);
                  onChange(id, d.tz('UTC').format("YYYY-MM-DD HH:mm"))
                  this.setState({ value: date.target.value });
                }} 
              />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 5
  },
  placeholder: {
    fontStyle: 'italic',
    color: colors.primary,
    fontSize: 20,
  },
  time: {
    fontSize: 20,
    color: "#333",
    fontWeight: "200",
    marginBottom: 5,
    fontFamily: "Roboto",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    height: 370,
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  androidPicker: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "lightgray",
    borderWidth: 1,
    borderRadius: 5
  },
  androidDate: {
    fontSize: 20,
    padding: 15,
    fontWeight: "bold",
    color: "gray"
  },
  datePicker: {
    padding: '1px 6px 1px 8px',
    borderRadius: '3px',

    borderWidth: 0,
    fontSize: '14px',
    width: '90%',
    borderColor: 'transparent'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #3b4a5c',
    fontSize: 16,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',

  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    height: 40,
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 10,
    overflow: "hidden",
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "300",
    fontFamily: "Roboto",
    display: "grid",
    gridAutoColumns: "100%",
    textAlign: "center",
  },
  buttonText2: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "300",
    fontFamily: "Roboto",
    display: "grid",
    gridAutoColumns: "100%",
    textAlign: "center",
  }
});
