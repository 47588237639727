import { handleActions } from "redux-actions";
import Immutable from "immutable";
import {
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_RESPONSE,
    PASSWORD_RESET_MESSAGE,
} from "../constants/actions";

const initialState = {
  email: {},
  errorVisible: false,
  message: "",
};

export default handleActions(
  {
    [PASSWORD_RESET_REQUEST]: (state, action) => {
      const { email } = action.payload;
      return state.set("email", email);
    },
    [PASSWORD_RESET_RESPONSE]: {
      next(state, action) {
        return state;
      }
    },
    [PASSWORD_RESET_MESSAGE]: (state, action) => {
      const { email } = action.payload;
      return state.set("message", action.payload);
    },    
  },
  Immutable.fromJS(initialState)
);
