export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGIN_LOADING_REPONSE = "LOGIN_LOADING_REPONSE";
export const LOGOUT = "LOGOUT";
export const AUTH_TOKENS_UPDATED = "AUTH_TOKENS_UPDATED";

export const MENU_REQUEST = "MENU_REQUEST";
export const MENU_RESPONSE = "MENU_RESPONSE";

export const FORM_REQUEST = "FORM_REQUEST";
export const FORM_RESPONSE = "FORM_RESPONSE";

export const SUBMIT_REQUEST = "SUBMIT_REQUEST";
export const SUBMIT_RESPONSE = "SUBMIT_RESPONSE";

export const NAVIGATE_MENU = "NAVIGATE_MENU";

export const SUBMIT_BEGIN = "SUBMIT_BEGIN";

export const INFO_DIALOG_OPEN = "INFO_DIALOG_OPEN";
export const INFO_DIALOG_CLOSE = "INFO_DIALOG_CLOSE";
export const LOG_DIALOG_OPEN = "LOG_DIALOG_OPEN";
export const LOG_DIALOG_CLOSE = "LOG_DIALOG_CLOSE";

export const SUBMISSION_ADD = "SUBMISSION_ADD";
export const SUBMISSION_SUCCESS = "SUBMISSION_SUCCESS";
export const SUBMISSION_START = "SUBMISSION_START";
export const SUBMISSION_ERROR = "SUBMISSION_ERROR";
export const LOAD_STORAGE = "LOAD_STORAGE";
export const LOADING_ISSUE = "LOADING_ISSUE";

export const IMAGE_REQUEST = "IMAGE_REQUEST";
export const IMAGE_REQUEST_FOUND = "IMAGE_REQUEST_FOUND";
export const IMAGE_RESPONSE = "IMAGE_RESPONSE";

export const SUBMISSION_PICTURE_START = "SUBMISSION_PICTURE_START";
export const SUBMISSION_PICTURE_SUCCESS = "SUBMISSION_PICTURE_SUCCESS";
export const SUBMISSION_PICTURE_ERROR = "SUBMISSION_PICTURE_ERROR";
export const SUBMISSION_FORM_START = "SUBMISSION_FORM_START";
export const SUBMISSION_FORM_SUCCESS = "SUBMISSION_FORM_SUCCESS";
export const SUBMISSION_FORM_ERROR = "SUBMISSION_FORM_ERROR";
export const SUBMISSION_STARTING = "SUBMISSION_STARTING";
export const SUBMISSION_FINISHED = "SUBMISSION_FINISHED";
export const SUBMISSION_DOCUMENT_START = "SUBMISSION_DOCUMENT_START";
export const SUBMISSION_DOCUMENT_SUCCESS = "SUBMISSION_DOCUMENT_SUCCESS";
export const SUBMISSION_DOCUMENT_ERROR = "SUBMISSION_DOCUMENT_ERROR";

export const RECORD_GET_REQUEST = "RECORD_GET_REQUEST";
export const RECORD_INPROGRESS_RESPONSE = "RECORD_INPROGRESS_RESPONSE";
export const RECORD_EDITREQUIRED_RESPONSE = "RECORD_EDITREQUIRED_RESPONSE";
export const RECORD_READYFORREVIEW_RESPONSE = "RECORD_READYFORREVIEW_RESPONSE";
export const RECORD_READYFORAPPROVAL_RESPONSE = "RECORD_READYFORAPPROVAL_RESPONSE";
export const SET_RECORD_STATUS_LOADING = "SET_RECORD_STATUS_LOADING";

export const KEYBOARD_SHOW = "KEYBOARD_SHOW";
export const KEYBOARD_HIDE = "KEYBOARD_HIDE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const INVALID_REGISTER_HIDE = "INVALID_REGISTER_HIDE";
export const SET_REGISTER_LOADING = "SET_REGISTER_LOADING";
export const SET_TASKS_STATUS = "SET_TASKS_STATUS";
export const SET_ALL_TASKS = "SET_ALL_TASKS";
export const SET_TASK_SELECTED = "SET_TASK_SELECTED";
export const SET_FILTER_SELECTED = "SET_FILTER_SELECTED";
export const SET_FORM_SELECTED = "SET_FORM_SELECTED";
export const SHOULD_RELOAD_TASKS = "SHOULD_RELOAD_TASKS";
export const SET_ARCHIVE_RECENTLY = "SET_ARCHIVE_RECENTLY";
export const ARCHIVE_TASKS = "ARCHIVE_TASKS";
export const REMOVE_TASK = "REMOVE_TASK";
export const CREATE_NEW_TASK = "CREATE_NEW_TASK";
export const SET_FORMS_FROM_API = "SET_FORMS_FROM_API";
export const SET_FORMS_LOADED = "SET_FORMS_LOADED";
export const SET_FORMS_LOADED_END = "SET_FORMS_LOADED_END";
export const SET_FORMS_EMPTY = "SET_FORMS_EMPTY";
export const GET_LOG_GROUPS = "GET_LOG_GROUPS";
export const GET_LOG_GROUPS_TASKS = "GET_LOG_GROUPS_TASKS";
export const SET_LOG_GROUP_SELECTED = "SET_LOG_GROUP_SELECTED";
export const SET_IS_LOG_GROUP = "SET_IS_LOG_GROUP";
export const GET_LOG_GROUPS_SUBMISSIONS = "GET_LOG_GROUPS_SUBMISSIONS";
export const SET_LOG_GROUPS_SUBMISSIONS = "SET_LOG_GROUPS_SUBMISSIONS";
export const SET_LOG_GROUPS_SUBMISSIONS_FALSE = "SET_LOG_GROUPS_SUBMISSIONS_FALSE";
export const SHOULD_RELOAD_LGSUBMISSIONS = "SHOULD_RELOAD_LGSUBMISSIONS";
export const SET_SUBMISSION_SELECTED = "SET_SUBMISSION_SELECTED";
export const  SET_IS_SUBMISSION = " SET_IS_SUBMISSION";
export const  GET_GROUP_DETAILS = " GET_GROUP_DETAILS";
export const  SIGN_TASK = " SIGN_TASK";
export const  SET_RECORDS_LOADING = "SET_RECORDS_LOADING";
export const SET_LOG_FILTER = "SET_LOG_FILTER";
export const ADD_DATA_TO_LOGRECORDS = "ADD_DATA_TO_LOGRECORDS";
export const SET_TASK_PAGE = "SET_TASK_PAGE";
export const SET_TASK_COUNT = "SET_TASK_COUNT";
export const SET_TASKLOG_PAGE = "SET_TASKLOG_PAGE";
export const SET_TASKLOG_COUNT = "SET_TASKLOG_COUNT";

export const SET_PAYMENT_COMPLETED = "SET_PAYMENT_COMPLETED";
export const SET_STATUS_CHANGE = "SET_STATUS_CHANGE";
export const UNSET_STATUS_CHANGE = "UNSET_STATUS_CHANGE";

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_RESPONSE = "PASSWORD_RESET_RESPONSE";
export const INVALID_PASSWORD_RESET_HIDE = "INVALID_PASSWORD_RESET_HIDE";
export const PASSWORD_RESET_MESSAGE = "PASSWORD_RESET_MESSAGE";

export const BIG_FILE_LOADING = 'BIG_FILE_LOADING';
export const PALLETS_REQUEST = 'PALLETS_REQUEST';
export const PALLETS_LOAD = 'PALLETS_LOAD';
export const ITEMS_REQUEST = 'ITEMS_REQUEST';
export const ITEMS_LOAD = 'ITEMS_LOAD';
export const PACKAGING_LOAD = 'PACKAGING_LOAD';
export const PACKAGING_REQUEST = 'PACKAGING_REQUEST';
export const QUIT_ORDER_FORM = "QUIT_ORDER_FORM";
export const UPDATE_SECTION_WIZARD = "UPDATE_SECTION_WIZARD";
export const SET_TITLE = "SET_TITLE";
export const GET_PROMPT_CATEGORIES = "GET_PROMPT_CATEGORIES";
export const SET_SUBMISSION_SELECTED_TASK_ID = "SET_SUBMISSION_SELECTED_TASK_ID";