const { AsyncStorage } = require('react-native');
class StorageService {
  constructor() {

    this.cache = {};
    /*this.load = AsyncStorage.getAllKeys()
      .then(keys => { return AsyncStorage.multiGet(keys); } )
      .then(keyValues => {
        const map = {};
        keyValues.forEach(pair => {
          try {
            map[pair[0]] = JSON.parse(pair[1]);
          } catch (ex) {  }
        });
        this.cache = map;
      });*/
  }

  getMenu() {
    return this.get('menu');
  }

  getShip() {
    return '';
    //return this.get('ship');
  }

  setMenu(menu) {
  	//this.set('ship', ship);
    this.set('menu', menu);
  }

  getForm(id) {
  	return this.get('forms') ? this.get('forms')[id] : null;
  }

  setForm(id, form) {
  	let forms = this.get('forms') || {};
  	forms[id] = form;
  	this.set('forms', forms);
  }

  getSubmissions() {
  	let submissions = this.get('submissions') || [];

    return submissions.map((o) => ({...o,
      date: new Date(o.date)}));
  }

  setSubmissions(submissions) {
  	this.set('submissions', submissions);
  }

  getPreviousSubmissions() {
    return this.get('previousSubmissions') || {};
  }

  setPreviousSubmission(id, submission) {
    let previousSubmissions = this.get('previousSubmissions') || {};

    previousSubmissions[id] = submission;

    this.set('previousSubmissions', previousSubmissions);
  }

  getImage(path) {
    return this.get('IMAGE' + path);
  }

  getSyncLog() {
    return this.get('syncLog');
  }

  addSyncLog(log) {
    let syncLog = this.get('syncLog') || [];
    syncLog.push(log);
    if (syncLog.length > 200)
      syncLog = syncLog.slice(syncLog.length-200);

    this.set('syncLog', syncLog);
  }

  setImage(path,image) {
    this.set('IMAGE'+path, image);
  }

  get(key) {
    return this.cache[key];
  }

  set(key, value) {
    this.cache[key] = value;
    //AsyncStorage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    try {
      delete this.cache[key];
      AsyncStorage.removeItem(key);
    } catch (ex) { /* ignore */ }
  }
}

module.exports = new StorageService();