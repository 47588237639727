const errorDescriptions = {
  'unknown_error': 'An unknown error occurred',
  'authentication_failed': 'Authentication failed',
  'batch_not_found': 'An internal error occurred (error code: 03)',
  'batch_already_started': 'An internal error occurred (error code: 04)',
  'batch_already_finished': 'An internal error occurred (error code: 05)',
  'uuid_mismatch': 'An internal error occurred (error code: 06)',
  'sensor_not_found': 'An internal error occurred (error code: 07)',
  'forbidden': 'An internal error occurred (error code: 10)',
  'connector_not_found': 'An internal error occurred (error code: 11)',
  'connector_exists': 'An internal error occurred (error code: 12)',
  'reading_not_found': 'An internal error occurred (error code: 13)',
  'signee_not_found': 'An internal error occurred (error code: 14)',
  'token_not_found': 'An internal error occurred (error code: 15)',
  'token_invalid': 'An internal error occurred (error code: 16)',
  'token_expired': 'An internal error occurred (error code: 17)',
  'key_not_found': 'An internal error occurred (error code: 18)',
  'role_not_allowed': 'An internal error occurred (error code: 19)',
  'upload_error': 'An internal error occurred (error code: 20)',
  'recipe_is_cooking': 'This recipe is cooking.',
  'validation_failed': 'An internal error occurred (error code: 22)',
};

const __DEV__ = true;

function getErrorDescription(errorCode) {
  return __DEV__ ? errorCode : errorDescriptions[errorCode] || errorDescriptions.unknown_error;
}

export default class ApiError extends Error {

  constructor(body) {
    const message = body.description || getErrorDescription(body.error);
    super(message);
    this.reason = body.error;
    this.message = message;
    this.isApiError = true;
  }

  toString() {
    return this.message;
  }
}
