import React, { Component } from "react";
import {
  StyleSheet,
  Dimensions,
  View,
  Text,
  TouchableHighlight,
  Image,
  ProgressViewIOS,
  Alert,
  Platform,
  TouchableOpacity,
  Modal as ModalWeb
} from "react-native";
// import ModalWeb from 'react-native-modalbox';
import { connect } from "react-redux";
// import ImagePicker from 'react-native-image-picker';
import * as ImagePicker from "expo-image-picker";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Lightbox from "react-native-lightbox";
import { getApiPath, uploadMediaB, uploadMediaWeb } from "../../api";
import * as Permissions from "expo-permissions";
import colors from "../../constants/colors";
import alerts from "./alert";
import NewIssue from "../dialogs/newIssue";
import Swal from "sweetalert2";
import { uploadFile } from "../../actions/submissions";

class Picture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      pictureText: this.props.placeholder,
      imagePath:
        typeof this.props.initial === "object"
          ? this.props.initial
          : "selected",
      imageState: this.props.initial ? "selected":"select",
      ignoreInitial: false,
      dialogVisible: false,
      showImageModal: false,
      modalImagePath: null
    };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({
        imagePath:
          typeof nextProps.initial === "object"
            ? nextProps.initial
            : "selected",
        imageState: nextProps.initial ? "selected":"select",});
    }
  }

  pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.6,
      base64:false 
    });

    if (!result.cancelled) {
      this.uploadImage(result.uri, result.type);
      // setImage(result.uri);
    }
  };

  takePhoto = async () => {
    const result = await ImagePicker.launchCameraAsync({
      allowEditing: false,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [4, 3],
      quality: 0.6,
    });

    if (!result.cancelled) {
      this.uploadImage(result.uri, result.type);
    }

  };

  showAlert = () =>{
    Swal.fire({
      text: "Where do you want to pick your image?",
      confirmButtonText: "Take a picture",
      denyButtonText: "Choose from gallery",
      cancelButtonText: "Cancel",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: colors.orange,
      backdrop: false

    }).then((result) => {
      if(result.isConfirmed) {
        this.pickCameraPermission();
      } else if(result.isDenied) {
        this.pickGalleryPermission();
      }
    });
  }

  pickCameraPermission = async () => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);

    if (status == "granted") {
      this.takePhoto();
    } else if (status == "denied") {
      Swal.fire({
        title: "We need your permission",
        text: "Please grant access to the camera in order to continue",
        confirmButtonText: "Take a picture",
        denyButtonText: "Choose from gallery",
        cancelButtonText: "Cancel",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: colors.orange,
        backdrop: false

      }).then((result) => {
        if(result.isConfirmed) {
          async () => {
            const { status } = await Permissions.askAsync(Permissions.CAMERA);
            if (status == "granted") {
              this.takePhoto();
            }
          }
        } else if(result.isDenied) {
          this.pickGalleryPermission();
        }
      });
    } else {
      Swal.fire({
        title: "We need your permission",
        text: "Please grant access to the camera in order to continue",
        confirmButtonText: "Take a picture",
        denyButtonText: "Choose from gallery",
        cancelButtonText: "Cancel",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: colors.orange,
        backdrop: false

      }).then((result) => {
        if(result.isConfirmed) {
          async () => {
            const { status } = await Permissions.askAsync(Permissions.CAMERA);
            if (status == "granted") {
              this.takePhoto();
            }
          }
        }
      });
    }
  };

  pickGalleryPermission = async () => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (status == "granted") {
      this.pickImage();
    } else if (status == "denied") {
      Swal.fire({
        title: "We need your permission",
        text: "Please grant access to the gallery so you can continue",
        confirmButtonText: "Accept",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        confirmButtonColor: colors.orange,
        backdrop: false

      }).then((result) => {
        if(result.isConfirmed) {
          async () => {
            const { status } = await Permissions.askAsync(
              Permissions.CAMERA_ROLL
            );
            if (status == "granted") {
              this.pickImage();
            }
          }
        }
      });
    }
  };

  updateUploadProgress(progress) {
    this.setState({ uploadProgress: progress });
  }

  uploadImage(uri, data) {
    if(Platform.OS=='ios'){
      this.setState({ imagePath: uri.replace("file://", "") });
    }else{
      this.setState({ imagePath: uri });
    }
    if(Platform.OS=='web'){
      //this.setState({ imagePath: uri.replace("file://", "") });
      data='image';
    }
    
    this.updateImage(data, uri);
    return;

    this.setState({
      imageState: "uploading",
      pictureText: "Uploading...",
    });

    var r = this.updateUploadProgress.bind(this);
    uploadMediaB(
      data,
      uri,
      function (written, total) {
        r(written / total);
      },
      (res) => {
        this.setState({ imagePath: uri.replace("file://", "") });
        var data = JSON.parse(res.data);
        this.updateImage(data.id);
      },
      (err) => {
        Swal.fire({
          text: "Error uploading file",
          confirmButtonText: "OK",
          confirmButtonColor: colors.orange,
          backdrop: false

        });
        this.setState({
          imageId: 0,
          uploadProgress: 0,
          imageState: "select", // select / uploading / selected
          pictureText: "No picture added",
        });
      }
    );
  }

  updateImage(data, uri) {
    const { id } = this.props;
    if(Platform.OS=='web'){
      if(uri.substr(5,6) != "image/"){  
        Swal.fire({
          text: "Please select only images.",
          confirmButtonText: "OK",
          confirmButtonColor: colors.orange,
          backdrop: false

        });
        return;
      }
      else {
        this.props.uploadFile(uri, (path)=>{
          this.props.onChange(id, path);
        })
      }
    }else{
      this.props.uploadFile(uri, (path)=>{
        this.props.onChange(id, { data, uri: path });
      })
      
    }
    

    this.setState({
      //imageId: id,
      imageState: "selected",
    });
  }

  progressBarStyle(progress) {
    return {
      position: "absolute",
      top: 0,
      left: 0,
      width: 370 * progress,
      bottom: 0,
      backgroundColor: "#6663",
    };
  }

  removePicture() {
    if(Platform.OS!='web'){
      Swal.fire({
        title: "Remove Picture",
        text: "Are you sure you would like to remove this picture?",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        confirmButtonColor: colors.orange,
        backdrop: true

      }).then((result) => {
        if(result.isConfirmed) {
          this.setState({
            imageId: 0,
            uploadProgress: 0,
            imageState: "select", // select / uploading / selected
            pictureText: "No picture added",
            imagePath: null,
            ignoreInitial: true,
          });
          this.props.onChange(this.props.id, 0);
        }
      });
    }else{
      Swal.fire({
        title: "Remove Picture",
        text: "Are you sure you would like to remove this picture?",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        confirmButtonColor: colors.orange,
        backdrop: false

      }).then((result) => {
        if(result.isConfirmed) {
          this.setState({
            imageId: 0,
            uploadProgress: 0,
            imageState: "select", // select / uploading / selected
            pictureText: "No picture added",
            imagePath: null,
            ignoreInitial: true,
          });
          this.props.onChange(this.props.id, 0);
        }
      });
    }
  }
  openImgModal(path){
    const {showImageModal} = this.state;
    this.setState({ showImageModal: true, modalImagePath: path});
  }
  closeImgModal(){
    this.setState({ showImageModal: false, modalImagePath: null});
  }
  getImageWidth(width){
    switch(width){
      case 'mini':
        return 134;
      case "small":
        return 200
      case "medium":
        return 300
      case "large":
        return 480
      default:
        return 480
    }

  }

  render() {    
    const {ignoreInitial, showImageModal, modalImagePath} = this.state; 
    const { initial,label, isIssuable, onSubmitIssue, id, isCompleted, width, openIssue, defaultComponent } = this.props;
    let initialImage=String(initial);
    if(initialImage==0)
        initialImage=false;

    if(initialImage!=false){ 
        if(!initialImage.includes('base64'))
            initialImage=getApiPath() + initial;
    }

    return (
      <>
        { !width ?
          <NewIssue  
            visible={this.state.dialogVisible} 
            id={id} 
            label={label} 
            onSubmitIssue={onSubmitIssue} 
            handleClose={()=>this.setState({dialogVisible:false})} 
            width={width}
          /> : null
        }

      <View style={styles.container}>
        <Text style={styles.label2}>{label}</Text>
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            if(width){
              openIssue();
            }else{
              this.setState({dialogVisible:true});
            }
          }}>
            <Text style={{color:colors.primary, fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }
        {
          showImageModal &&
          <ModalWeb
          animationType="slide"
          transparent={true}
          visible={showImageModal}
          
        >
          <View style={styles.modalImgContainer}>
            <Text style={styles.closeImgModal} onPress={() =>  this.closeImgModal()}>
              X
            </Text>
            <img src={modalImagePath.uri} style={{maxHeight: "100%", maxWidth: "100%"}} />
          </View>      
        </ModalWeb>
        }

        {this.state.imageState == "selected" ? (
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
            <View style={styles.wrapperUploaded}>
             {Platform.OS === 'web' ? (
               <TouchableOpacity
                onPress={() => this.openImgModal(initialImage && !ignoreInitial ? {uri: initialImage } : { uri: this.state.imagePath }) }
               >
                  {/* <Image 
                    source={initialImage && !ignoreInitial ? {uri: initialImage } :  { uri: this.state.imagePath } }
                    style={{
                      width: this.getImageWidth(width), 
                      height: this.getImageWidth(width)
                    }}
                  /> */}
                  {/* <div style={{ justifyContent: 'center', width: this.getImageWidth(width) + 20, maxHeight: this.getImageWidth(width), alignSelf: 'center', overflowY: 'auto' }}> */}
                    <img 
                      src={initialImage && !ignoreInitial ? initialImage : this.state.imagePath} 
                      style={{
                        width: this.getImageWidth(width), 
                        height: 'auto'
                      }} />
                  {/* </div> */}
               </TouchableOpacity> 
             ): (
              <TouchableOpacity
                onPress={() => this.openImgModal(initialImage && !ignoreInitial ? {uri: initialImage } :  { uri: this.state.imagePath }) }
              >
                  <Image 
                    source={initialImage && !ignoreInitial ? {uri: initialImage } :  { uri: this.state.imagePath } }
                    style={{                  
                      aspectRatio: 1,
                      width: Dimensions.get("window").width - 80,
                      height: 320,
                    }}
                  />
              </TouchableOpacity>
              
             )}
            </View>
            <TouchableHighlight
              style={{
                position: "absolute",
                top: 20,
                right: 20,
                backgroundColor: "white",
                height: 40,
                width: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
              }}
              onPress={() => {
                if(!defaultComponent){
                  this.removePicture();
                }
              }}
            >
              <MaterialCommunityIcons
                name="delete"
                size={28}
                color="red"
                style={styles.icon}
              />
            </TouchableHighlight>
          </View>
        ) : (
          <View style={styles.wrapperInitial}>
            {/* <View style={this.progressBarStyle(this.state.uploadProgress)}/> */}
            <TouchableHighlight
              disabled={defaultComponent}
              style={styles.button}
              underlayColor={colors.secondary}
              onPress={Platform.OS=='web'? this.pickImage:this.showAlert}
            >
              <Text style={width == "small" ? styles.buttonText2 : styles.buttonText}>Add picture</Text>
            </TouchableHighlight>
            <Text style={styles.label}>{this.state.pictureText}</Text>
          </View>
        )}
      </View>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      state,
    }
  },
  (dispatch, props) => {
    return {
      uploadFile: (uri, callback) => dispatch(uploadFile(null, uri, ".png", callback)),
    };
  }
)(Picture);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingTop: 5,
    // paddingHorizontal: 10,
    // paddingBottom: 10,
  },
  wrapperInitial: {
    flexDirection: "row",
    alignItems: "center",
    // padding: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#C8C9CB",
    borderRadius: 5,
  },
  wrapperUploaded: {
    flexDirection: "row",
    alignItems: "flex-start",
    // padding: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#C8C9CB",
    borderRadius: 5,
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    height: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    overflow: "hidden",
  },
  buttonText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "300",
    fontFamily: "Roboto",
  },
  buttonText2: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "300",
    fontFamily: "Roboto",
  },
  label: {
    fontSize: 16,
    fontWeight: "300",
    marginLeft: 10,
    fontFamily: "Roboto",
    fontStyle: "italic",
    color: "#424242",
    wordBreak: 'break-all'
  },
  image: {
    maxHeight: 80,
  },
  label2: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
  modalImgContainer:{
    width: "100%",
    height: "100%",
    alignItems: "center",
    position: "relative",
    backgroundColor: "rgba(0,0,0,0.4)",
    overflow: "hidden",
    justifyContent: "center"
  },
  closeImgModal: {
    position: "absolute",
    top: 10,
    right: 20,
    fontSize: 22,
    color: "white"
  },
  imgModal:{
    height: 500, 
    width: 500
  }

});
