import { createAction } from '@reduxjs/toolkit'
import {
  MENU_REQUEST,
  MENU_RESPONSE,
  FORM_REQUEST,
  FORM_RESPONSE,
  SUBMIT_REQUEST,
  SUBMIT_RESPONSE,
  NAVIGATE_MENU,
  SUBMIT_BEGIN,
  INFO_DIALOG_OPEN,
  INFO_DIALOG_CLOSE,
  SUBMISSION_ADD,
  IMAGE_REQUEST,
  IMAGE_REQUEST_FOUND,
  IMAGE_RESPONSE,
  SET_TASKS_STATUS,
} from "../constants/actions";

import StorageService from "../services/storage";
import { getTasks } from './tasks';
import { requestMenu, sendForm, requestForm, getImage, getRecordStatus, getLogStatus, editDefaultData as _editDefaultData } from "../api";
import { Alert } from "react-native";
import uuid from "uuid";
import { sync, startingSync } from "./submissions";
import Swal from 'sweetalert2';
import colors from '../constants/colors';
export function getMenu() {
  return (dispatch, getState) => {
    dispatch(createAction(MENU_REQUEST)());
    dispatch(
      createAction(MENU_RESPONSE)(
        requestMenu(dispatch, getState).then((response) => ({ response }))
      )
    );
  };
}

export function getMenuImages() {
  return (dispatch, getState) => {
    const menu = getState().forms.getIn(["menu", "items"]);
    dispatch(createAction(IMAGE_REQUEST)());

    menu.forEach((f) => {
      if (f.image) {
        if (!StorageService.getImage(f.image)) {
          var p = getImage(f.image).then((response) => ({
            id: f.id,
            url: f.image,
            response,
          }));
          dispatch(createAction(IMAGE_RESPONSE)(p));
        } else {
          dispatch(
            createAction(IMAGE_REQUEST_FOUND)({ id: f.id, url: f.image })
          );
        }
      }
    });
  };
}

export function navigateForm(id, name, newReport, task_id, log_group_id) {
  return (dispatch, getState) => {
    
  };
}

export function navigateMenu(id, name) {
  return (dispatch, getState) => {
    dispatch(createAction(NAVIGATE_MENU)());
  };
}

export function getForm(id, idSubmission, callback) {
  console.log('ID'+id)
  
  return (dispatch, getState) => {
    console.log("### GET FORM ACTION!");
    dispatch(createAction(FORM_REQUEST)({ id }));

    requestForm(dispatch, getState, id, idSubmission).then(
      (response) => {     
        if(callback)callback();
        dispatch(createAction(FORM_RESPONSE) (response)); 
        return response;
      });

  };
}

export function submitForm(submission) {
  return (dispatch, getState) => {
    dispatch(createAction(SUBMIT_REQUEST)());
    dispatch(
      createAction(SUBMIT_RESPONSE)(
        sendForm(dispatch, getState).then((response) => ({ response }))
      )
    );
  };
}

export function beginSubmit( 
  id,
  title,
  components,
  choicelist,
  submission,
  uuid,
  task_id,
  isAutoSave,
  logroup,  
  status,
  isRecipe,
  navigate,
  retry,
  sendAnyway,
  isInventoryTransfer
) {
  return (dispatch, getState) => {
          dispatch(
            createAction(SUBMISSION_ADD)({
              report: title,
              date: new Date(),
              id: id,
              components: components,
              choicelist: choicelist,
              submission: submission,
              status,
              uuid: uuid,
              task_id: task_id,
              isAutoSave,
              logroup: logroup,
              isRecipe,
              isInventoryTransfer       
            })
          );
          dispatch(createAction(SET_TASKS_STATUS)("loading"));
          dispatch(startingSync());
          setTimeout(() => {
            dispatch(sync(navigate, retry, sendAnyway));
          }, 300);
        
        return;

  };
}

export function checkRecordStatus(uuid, callback){
  return (dispatch, getState) => {
    getRecordStatus(dispatch, getState, uuid)
      .then((res) => {
        const status = res?.status ? res?.status  : "";
        if(callback)callback(status);
      });
  };
}
export function checkLogStatus(task_id, callback){
  return (dispatch, getState) => {
    getLogStatus(dispatch, getState, task_id).then((res)=>{
      const status = res?.status ? res?.status  : "";
      if(callback)callback(status);
    });
  }
}
export function showStatusError(status){
  return (dispatch, getState) => {
    dispatch(getTasks());
    if(status)status = status.replace("_"," ");
    Swal.fire({
      text: "This record is actually " + status + " in other device.",
      confirmButtonText: "OK",
      confirmButtonColor: colors.orange,
      backdrop: false
    });
  }
}
export function showLogStatusError(status){
  return (dispatch, getState) => {
    dispatch(getTasks());
    if(status)status = status.replace("_"," ");
    Swal.fire({
      text: "This log is actually " + status + " in other device.",
      confirmButtonText: "OK",
      confirmButtonColor: colors.orange,
      backdrop: false
    });
  }
}

export function infoDialog() {
  return (dispatch, getState) => {
    dispatch(createAction(INFO_DIALOG_OPEN)());
  };
}

export function infoDialogClose() {
  return (dispatch, getState) => {
    dispatch(createAction(INFO_DIALOG_CLOSE)());
  };
}

export function navigateSubmission(id, name, newReport, log_group_id) {
  return (dispatch, getState) => {

  };
}

export function navigateRecord(id, name, newReport) {
  return (dispatch, getState) => {
    //Actions.form({ formId: id, title: name, newReport});
    
  };
}

export function editDefaultData(data, callback) {
  return (dispatch, getState) => {
    _editDefaultData(dispatch, getState, data).then((res)=>{
      if(callback)callback();
    });
    
  };
}
