import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  TouchableOpacity,
  TouchableHighlight,
  Dimensions,
  Alert,
  Platform,
  Modal
} from "react-native";
import ModalWeb from 'react-native-modalbox';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { connect } from "react-redux";

import { register, resetRegister } from "../actions/register";
import colors from "../constants/colors";
import Animations from './animations';
import alerts from '../components/components/alert';
import Swal from "sweetalert2";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      repeat_password: "",
      company: "",
      phone_number: "",     
      company_size: { value: "" },
      position: "",
      city: "",
      state: { value: "" },
      country: { value: "" },
      emailValid: false,
      showModal: true,
    };
  }

  validateForm = () =>{
    if(this.state.first_name==''){
      Swal.fire({
        text: "Please Add First Name",
        confirmButtonText: "OK",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      return true;
    }
    if(this.state.last_name==''){
      Swal.fire({
        text: "Please Add Last Name",
        confirmButtonText: "OK",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      return true;
    }
    if(this.state.first_name==''){
      Swal.fire({
        text: "Please Add First Name",
        confirmButtonText: "OK",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      return true;
    }
    if(this.state.email==''){
      Swal.fire({
        text: "Please Add Email",
        confirmButtonText: "OK",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      return true;
    }
    if(this.state.password==''){
      Swal.fire({
        text: "Please Set Password",
        confirmButtonText: "OK",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      return true;
    }
    if(this.state.repeat_password==''){
      Swal.fire({
        text: "Please Repeat Password",
        confirmButtonText: "OK",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      return true;
    }
    return false;  
  }

  sendRegisterRequest = () => {
    let formValidation= this.validateForm();
    if(!formValidation){
      if (this.validateEmail()) {
        if (this.validatePassword()) {
          if (this.validateState()) {
            let obj = {};
            obj.first_name = this.state.first_name;
            obj.last_name = this.state.last_name;
            obj.email = this.state.email;
            obj.password = this.state.password;
            obj.company = this.state.company;
            obj.phone_number = this.state.phone_number;
            obj.company_size = this.state.company_size;
            obj.position = this.state.position;
            obj.city = this.state.city;
            if (
              this.state.country == "Canada" ||
              this.state.country == "United States"
            ) {
              obj.state = this.state.state;
            }
            obj.country = this.state.country;
            
            this.props.register(obj);
          } else {
            Swal.fire({
              text: "A state must be selected",
              confirmButtonText: "Close",
              confirmButtonColor: colors.orange,
              backdrop: false

            });
          }
        }else{
          Swal.fire({
            text: "Passwords does not match",
            confirmButtonText: "Close",
            confirmButtonColor: colors.orange,
            backdrop: false

          });
        }
      }
    } 
  };

  validateEmail = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.state.email.toLowerCase())) {
      Swal.fire({
        text: "Please, enter a valid email",
        confirmButtonText: "Close",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      return false;
    }
    return true;
  };

  validatePassword = () => {
    const { password, repeat_password } = this.state;
    if (password == repeat_password) {
      return true;
    } else {
      return false;
    }
  };

  validateState = () => {
    const { state, country } = this.state;
    if (country == "United States" || country == "Canada") {
      if (state != "") {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      password,
      repeat_password,
      company,
      phone_number,
      company_size,
      position,
      city,
      state,
      country,
    } = this.state;
    const {registerLoading, registerComplete} = this.props;  
    if(registerComplete){
      Swal.fire({
        text: "Your account has been created succesfully. Please check your email for instructions to active your account.",
        confirmButtonText: "Close",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      this.props.resetRegister();
      this.props.navigation.navigate('Login');
    }
    return (
      
        
        <ScrollView style={{ flex: 1 }}>         
          <View style={styles.containerInside}>
            <View style={styles.card}>
              <Text style={styles.subtitle}>Create an account</Text>
              <Text style={styles.text}>
                Complete the next form to create your account
              </Text>
              <TextInput
                value={first_name}
                style={styles.input}
                autoCapitalize="words"
                placeholder={"First name"}
                onChangeText={(val) => {
                  this.setState({ first_name: val });
                }}
              />

              <TextInput
                value={last_name}
                style={[styles.input]}
                autoCapitalize="words"
                placeholder={"Last name"}
                onChangeText={(val) => {
                  this.setState({ last_name: val });
                }}
              />
              <TextInput
                value={phone_number}
                style={styles.input}
                autoCapitalize="none"
                placeholder={"Phone number (Optional)"}
                onChangeText={(val) => {
                  this.setState({ phone_number: val });
                }}
              />
              <TextInput
                value={email}
                style={styles.input}
                autoCapitalize="none"
                placeholder={"Email"}
                onChangeText={(val) => {
                  this.setState({ email: val });
                }}
                onBlur={() => {
                  this.validateEmail();
                }}
              />

              <TextInput
                secureTextEntry={true}
                value={password}
                style={[styles.input]}
                placeholder={"Password"}
                onChangeText={(val) => {
                  this.setState({ password: val });
                }}
                onBlur={() => {
                  if (password.length < 8) {
                    Swal.fire({
                      text: "Your password must contain at least 8 characters",
                      confirmButtonText: "OK",
                      confirmButtonColor: colors.orange,
                      backdrop: false

                    });
                  }
                }}
              />

              <TextInput
                secureTextEntry={true}
                value={repeat_password}
                style={[styles.input]}
                placeholder={"Repeat password"}
                onChangeText={(val) => {
                  this.setState({ repeat_password: val });
                }}
                onBlur={() => {
                  if (password != repeat_password) {
                    Swal.fire({
                      text: "Passwords do not match",
                      confirmButtonText: "OK",
                      confirmButtonColor: colors.orange,
                      backdrop: false

                    });
                  }
                }}
              />

              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 25,
                }}
              >
                <TouchableOpacity
                  style={styles.buttonCancel}
                  onPress={()=> this.props.navigation.navigate('Login')}
                >
                  <Text style={{ color: colors.commandSecondayText, fontSize: 18 }}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    if(!registerLoading){                      
                      this.sendRegisterRequest();
                    }                    
                  }}
                >
                  <View style={{display: 'flex', flexDirection:'row'}}>
                  {registerLoading ?  <Text style={{ color: colors.userCommandText, fontSize: 18, paddingTop: 15 }}>Register</Text>:<Text style={{ color: colors.userCommandText, fontSize: 18, paddingTop: 0 }}>Register</Text>}
                    {registerLoading ? <Animations animationfile='loading-dots' />:null}
                  </View>    
                </TouchableOpacity>
              </View>
            </View>
          </View>
          
          <View
        style={styles.centeredView}
      >
        {Platform.OS=='web'?
                  <ModalWeb
                  animationType="slide"
                  transparent={false}
                  visible={this.state.showModal}
                  onDismiss={() => {
                    this.setState({
                      showModal: false,
                    });
                  }}
                >
                    <View
                      style={{
                        width: "90%",
                        backgroundColor: "white",
                        paddingHorizontal: 20,
                        paddingVertical: 10,
                        borderRadius: 12,
                        margin: 'auto',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          paddingVertical: 10,
                          fontFamily: "Roboto",
                          textAlign: "justify",
                          lineHeight: 24,
                        }}
                      >
                        Thank you for downloading{" "}
                        <Text style={{ fontFamily: "Roboto-Bold" }}>GoHACCP</Text>, and
                        congratulations on taking your first steps to full compliance
                        efficiency.
                      </Text>
                      <Text
                        style={{
                          fontSize: 18,
                          paddingVertical: 10,
                          fontFamily: "Roboto",
                          textAlign: "justify",
                          lineHeight: 24,
                        }}
                      >
                        This app is the user interface of a powerful compliance
                        software package. In order to take full advantage of the
                        system you will need to set up an admin interface.
                      </Text>
                      <Text
                        style={{
                          fontSize: 18,
                          paddingVertical: 10,
                          fontFamily: "Roboto",
                          textAlign: "justify",
                          lineHeight: 24,
                        }}
                      >
                        This will allow you to create, personalise your reports, and
                        access our compliance dashboard.
                      </Text>
                      <Text
                        style={{
                          fontSize: 18,
                          paddingVertical: 10,
                          fontFamily: "Roboto",
                          textAlign: "justify",
                          lineHeight: 24,
                        }}
                      >
                        We also have a world class compliance team standing by to help
                        you write your programs. Please add your phone number if you
                        would like us to contact you by phone for support.
                      </Text>
                      <Text
                        style={{
                          fontSize: 18,
                          paddingVertical: 10,
                          fontFamily: "Roboto",
                          textAlign: "justify",
                          lineHeight: 24,
                        }}
                      >
                        Thank you,{" "}
                        <Text style={{ fontFamily: "Roboto-Bold" }}>GoHACCP team.</Text>
                      </Text>
                      <TouchableHighlight
                        style={{
                          height: 60,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: colors.primary,
                          borderRadius: 8,
                          marginVertical: 10,
                        }}
                        onPress={() => {
                          this.setState({
                            showModal: false,
                          });
                        }}
                      >
                        <Text style={{ color: "white", fontSize: 18 }}>Ok</Text>
                      </TouchableHighlight>
                    
                      </View>
                    </ModalWeb>
        :<Modal
        animationType="slide"
        transparent={true}
        visible={this.state.showModal}
        onRequestClose={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "#00000044",
            justifyContent: "center",
            alignItems: "center",       
            padding : 20,         
          }}
        >
          <ScrollView
            style={{                           
              flexGrow: 0,         
              backgroundColor: "white",
              paddingHorizontal: 20,
              paddingVertical: 10,
              borderRadius: 12,
            }}
          >
            <Text
              style={{
                fontSize: 18,
                paddingVertical: 10,
                fontFamily: "Roboto",
                textAlign: "justify",
                lineHeight: 24,
              }}
            >
              Thank you for downloading{" "}
              <Text style={{ fontFamily: "Roboto-Bold" }}>GoHACCP</Text>, and
              congratulations on taking your first steps to full compliance
              efficiency.
            </Text>
            <Text
              style={{
                fontSize: 18,
                paddingVertical: 10,
                fontFamily: "Roboto",
                textAlign: "justify",
                lineHeight: 24,
              }}
            >
              This app is the user interface of a powerful compliance
              software package. In order to take full advantage of the
              system you will need to set up an admin interface.
            </Text>
            <Text
              style={{
                fontSize: 18,
                paddingVertical: 10,
                fontFamily: "Roboto",
                textAlign: "justify",
                lineHeight: 24,
              }}
            >
              This will allow you to create, personalise your reports, and
              access our compliance dashboard.
            </Text>
            <Text
              style={{
                fontSize: 18,
                paddingVertical: 10,
                fontFamily: "Roboto",
                textAlign: "justify",
                lineHeight: 24,
              }}
            >
              We also have a world class compliance team standing by to help
              you write your programs. Please add your phone number if you
              would like us to contact you by phone for support.
            </Text>
            <Text
              style={{
                fontSize: 18,
                paddingVertical: 10,
                fontFamily: "Roboto",
                textAlign: "justify",
                lineHeight: 24,
              }}
            >
              Thank you,{" "}
              <Text style={{ fontFamily: "Roboto-Bold" }}>GoHACCP team.</Text>
            </Text>

            <TouchableOpacity
              style={{
                height: 60,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.primary,
                borderRadius: 8,
                marginVertical: 10,
              }}
              onPress={() => {
                this.setState({
                  showModal: false,
                });
              }}
            >
              <Text style={{ color: "white", fontSize: 18 }}>Ok</Text>
            </TouchableOpacity>                
          </ScrollView>
        </View>
      </Modal>}
          
      </View>  
          
        </ScrollView>
        
   

      
    );
  }
}

export default connect(
  (state) => {
    return {
      registerLoading: state.register.get("registerLoading"),
      registerComplete: state.register.get("isRegisterCompleted"),
    };
  },
  (dispatch, props) => {
    return {
      register: (user_data) => {
        dispatch(register(user_data));
      },
      resetRegister: () => {
        dispatch(resetRegister());
      },
    };
  }
)(Register);

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },

  buttonText: {
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: 14,
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 6,
    flex: 1,
    marginVertical: 50,
    padding: 60,
    shadowColor: "black",
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 7,
      height: 5,
    },
    shadowRadius: 5,
    minHeight: 600,
  },
  loadingAnimation: { 
    height: 20,
    width: 20,
    position: "absolute",
    right: 5,     
  },
  container: {
    backgroundColor: "#B3B7BC",
    flex: 1,
    flexDirection: "column",
  },
  containerInside: {
    alignItems: "center",
    backgroundColor: "#B3B7BC",
    flex: 1,
    minHeight: 600,
    flexDirection: "column",
    justifyContent: "center",
  },
  topBar: {
    paddingVertical: 20,
    backgroundColor: colors.primary,
    justifyContent: "center",
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 5,
    shadowOpacity: 0.2,
    shadowColor: "black",
    zIndex: 10,
  },
  title: {
    color: "#fff",
    fontSize: 40,
    textAlign: "center",
    fontWeight: "600",
  },
  subtitle: {
    fontSize: 22,
    fontWeight: "600",
    paddingTop: 10,
    paddingBottom: 10,
    color: colors.primary,
  },

  shipName: {
    paddingTop: 10,
    color: "#fff",
    fontSize: 18,
    textAlign: "center",
    fontWeight: "400",
  },
  wordTwo: {
    fontWeight: "300",
  },
  loadingText: {
    color: "#444",
    marginTop: 5,
  },
  errorText: {
    color: "#444",
    marginLeft: 5,
  },
  input: {
    borderColor: "#ccc",
    borderWidth: 1,
    color: "#444",
    fontSize: 18,
    height: 58,
    marginTop: 15,
    marginBottom: 15,
    paddingBottom: 0,
    paddingLeft: 15,
    borderRadius: 3,
  },
  button: {
    flex: 1,
    backgroundColor: colors.userCommand,
    padding: 0,
    height: 50,
    borderRadius: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
    marginLeft: 15,
    borderRadius: 5,
  },
  buttonCancel: {
    flex: 1,
    backgroundColor: "#ccc",
    padding: 0,
    height: 50,
    borderRadius: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
    marginRight: 15,
    borderRadius: 5,
  },
  text: {
    fontSize: 16,
    fontWeight: "normal",
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "left",
    color: "#888",
  },
});
