import 'react-native-gesture-handler';
import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import Login from './components/login';
import Forgot from './components/forgot';
import Register from './components/register';
import MainView from './components/mainview';
import RecordsView from './components/records';
import TaskDetail from './components/taskDetail';
import FormView from './components/form';
import NewTask from './components/newTask';
import NewLogTask from './components/newLogTask';
import GroupView from './components/groupView';
import PromptFormView from './components/promptFormView';
import SubmenuPrompts from './components/SubmenuPrompts';
import { createStackNavigator } from '@react-navigation/stack';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import colors from "./constants/colors";
import { logout } from "./actions/login";
import {
  Image, View, TouchableOpacity, Text, NativeModules, Platform 
} from 'react-native';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import TitleBar from "./components/widgets/NTitlebar";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { BRANDING } from "@env";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

function LoginScreen({ navigation }) {
  return (<Login navigation={navigation} />);
}
function ForgotScreen({ navigation }) {
  return (<Forgot navigation={navigation} />);
}
function RegisterScreen({ navigation }) {
  return (<Register navigation={navigation} />);
}
function MainViewScreen({ navigation }) {
  return (<MainView navigation={navigation} />);
}
function RecordsViewScreen({ navigation }) {
  return (<RecordsView navigation={navigation} />);
}

function TaskDetailScreen({ navigation }) {
  return (<TaskDetail navigation={navigation} />);
}
function FormViewScreen({ route, navigation }) {
  // console.log(route.params)
  return (<FormView navigation={navigation} formId={route.params.formId} title={route.params.title} isSubmission={route.params.submission} newReport={route.params.newReport} task_id={route.params.task_id} log_group_id={route.params.log_group_id} promptMessages={route.params.promptMessages} isPallet={route.params.isPallet} isReceivingLog={route.params.isReceivingLog} isOrder={route.params.isOrder} isRecipe={route.params.isRecipe} defaultYield={route.params.defaultYield} isInvoice={route.params.isInvoice} isShipmentLog={route.params.isShipmentLog} isReconciliationLog={route.params.isReconciliationLog} isInventoryTransfer={route.params.isInventoryTransfer}/>);
}
function NewTaskScreen({ navigation, route }) {
  return (<NewTask navigation={navigation} route={route} />);
}
function NewLogTaskScreen({ navigation }) {
  return (<NewLogTask navigation={navigation} />);
}
function GroupViewScreen({ route, navigation }) {
  return (<GroupView navigation={navigation} signature={route.params?.signature}/>);
}

function PromptFormScreen({ navigation, route }) {
  return (<PromptFormView navigation={navigation} route={route} />);
}
function SubmenuScreen({ navigation, route }) {
  return (<SubmenuPrompts navigation={navigation} route={route} />);
}  

function LogoTitle() {
  return (
    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      {
        BRANDING == "ghcs" ? 
        <Image
          style={{ width: 150, height: 50 }}
          source={require("../assets/logo_white.png")}
        /> : 
        <Image
          style={{ width: 150, height: 50 }}
          source={require("../assets/iQOps-app-white.png")}
        />
      }
      
    </View>
  );
}

function Logout({ navigation }) {
  const dispatch = useDispatch();
  return (
    <View style={{ display: 'flex', flexDirection: "row" }}>
      <div style={{ display: 'flex', flex: 1, justifyContent: "center", flexDirection: "row", alignItems: 'center', position: "relative" }}>
        <Text style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>Dashboard</Text>
        <TouchableOpacity
          style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', flexDirection: "row", position: "absolute", right: 5 }}
          onPress={() => {
            Swal.fire({
              text: "Are you sure you want to logout?",
              showCancelButton: true,
              cancelButtonText: "Cancel",
              confirmButtonText: "OK",
              confirmButtonColor: colors.orange,
              backdrop: false
            }).then((result) => {
              if(result.isConfirmed) {
                if (Platform.OS === 'web') {
                  //location.reload();
                  window.location.reload(false);
                } else {
                  dispatch(logout());
                  NativeModules.DevSettings.reload();
                }
              }
            });
          }}

        >
          <Text style={{ color: colors.logout, }}>Logout</Text>
          <MaterialCommunityIcons
            name="account-circle"
            size={22}
            color="#fff"
            style={{
              height: 24,
              width: 24,
              color: colors.logout
            }}
          />
        </TouchableOpacity>
      </div>
    </View>
  );
}
const Stack = createStackNavigator();

export default function App() {
  return (
    <Provider store={store}>
      <NavigationContainer>
        <Stack.Navigator>
          {/* <Stack.Screen name="PromptForm" options={{
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center', color: "#ffffff"

            }
          }} component={PromptFormScreen} /> */}
          <Stack.Screen name="Login" options={{
            headerTitle: props => <LogoTitle {...props} />, title: "Login", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold'

            }
          }} component={LoginScreen} />
          <Stack.Screen name="Forgot" options={{
            headerTitle: props => <LogoTitle {...props} />, title: "Forgot Password", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold'

            }
          }} component={ForgotScreen} />
          <Stack.Screen name="Register" options={{
            title: "Register", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            }
          }} component={RegisterScreen} />
          <Stack.Screen name="MainView" options={{
            headerTitle: props => <Logout {...props} />, title: "Records", headerLeft: () => null , headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center'
            },
          }} component={MainViewScreen} />
          <Stack.Screen name="RecordsView"
            options={{
              headerTitle: props => <TitleBar title="SUBMITTED FORMS" {...props} />,
              headerTintColor: colors.back,
              headerStyle: {
                backgroundColor: colors.primary,
              }
            }}
            component={RecordsViewScreen}
          />
          <Stack.Screen name="TaskDetail" options={{
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            }
          }} component={TaskDetailScreen} />
          <Stack.Screen name="FormView" options={({ navigation, route }) => ({
            title: route.params.title,
            headerLeft: () => (
              <TouchableOpacity
                onPress={() => {
                  const status = store.getState().submissions.getIn(["submissionSelected","status"]);
                  const formSubmission = store.getState().forms.getIn(["form","formSubmission"]);

                  if(status == 'archived' || status == 'approved' || formSubmission){
                    navigation.goBack();
                    return;  
                  }
                  Swal.fire({
                    text: "If you leave this form, data you have entered may not be saved. Are you sure you want to leave this form?", 
                    confirmButtonText: "OK",
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    confirmButtonColor: colors.orange,
                    backdrop: false
                  }).then((result) => {
                    if(result.isConfirmed)
                      navigation.goBack();
                  })

                }}
                title="Info"
                color={colors.back}
              >
                <MaterialCommunityIcons
                  name="arrow-left"
                  size={22}
                  color={colors.back}
                  style={{
                    height: 24,
                    width: 24,
                    marginLeft: 20,
                    marginBottom: 5,
                    color: colors.back
                  }}
                />
              </TouchableOpacity>

            ),
            headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center', color: "#ffffff"
            },
          })} component={FormViewScreen} />
          <Stack.Screen name="NewTask" options={{
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',  color: "#ffffff"

            }
          }} component={NewTaskScreen} />
          <Stack.Screen name="NewLogTask" options={{
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center', color: "#ffffff"

            }
          }} component={NewLogTaskScreen} />
          <Stack.Screen name="GroupView" options={{
            title: "Groups", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center', color: "#ffffff"

            }
          }} component={GroupViewScreen} />
          <Stack.Screen name="PromptFormView" options={{
            headerTitle: props => <TitleBar title="Title" prompt={true} {...props} />,
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center', color: "#ffffff"
            }
          }} component={PromptFormScreen} />
          <Stack.Screen name="SubmenuView" options={{
            headerTitle: props => <TitleBar title="Customize my assistant" prompt={true} {...props} />,
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: colors.back,
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center', color: "#ffffff"
            }
          }} component={SubmenuScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  );
}