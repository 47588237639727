import React from "react"
import {
    Text,
    StyleSheet,
    View,
    TouchableOpacity,
} from "react-native"
import colors from "../../constants/colors"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { useDispatch, useSelector } from 'react-redux'
import { getAllRecords, getAllTaskFromLog, setLogFilter } from "../../actions/records"
import moment from "moment-timezone"
import Animations from "../../components/animations"

export default function ({ title, prompt, ...props }) {
    const dispatch = useDispatch()
    const lastUpdated = useSelector(state => state.records.get("lastFormUpdated"))
    const isLoading = useSelector(state => state.records.get("recordsLoading"))
    const promptTitle = useSelector(state => state.tasks.get("promptTitle"))

    const getTime = (date) => {
        let d = moment.tz(date, "UTC");
        if (moment.tz(d, moment.tz.guess(true)).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))
            return "Today at " + moment.tz(d, moment.tz.guess(true)).format("h:mm A");
        else
            return moment.tz(d, moment.tz.guess(true)).format("MM/DD/YYYY");
    }
    return <View style={styles.container}>
        <View style={styles.titleContainer}>
            <Text style={styles.text}>{prompt ? promptTitle ? promptTitle : title : title}</Text>
            {
                !prompt && <Text style={{ ...styles.text, color: colors.back }}>Last Updated : {getTime(lastUpdated)}</Text>
            }
        </View>
        {
            isLoading ?
                <TouchableOpacity
                    style={{ ...styles.accountButton, color: colors.back }}
                >
                    <Animations animationfile='loading-dots' />
                </TouchableOpacity>
                :
                prompt ? <View style={styles.accountButton}></View> : 
                <TouchableOpacity
                    style={styles.accountButton}
                    onPress={() => {
                        dispatch(getAllRecords({ page: 1 }))
                        dispatch(getAllTaskFromLog({ page: 1 }))
                        dispatch(setLogFilter(null));
                    }}
                >
                    <MaterialCommunityIcons
                        name="refresh"
                        size={25}
                        color="#fff"
                        style={{ ...styles.icon, color: colors.back }}
                    />
                </TouchableOpacity>
        }

    </View>
}

const styles = StyleSheet.create({
    loadingAnimation: {
        height: 60,
        width: 60,
        position: "absolute",
        right: -5,
    },
    titleContainer: {
        flex: 0.95,
        flexDirection: "column"
    },
    inner: {
        flex: 1
    },
    icon: {
        height: 24,
        width: 24,
    },
    accountButton: {
        flex: 0.05,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    container: {
        color: colors.userCommandText,
        flex: 1,
        flexDirection: "row",
        justifyContent: 'flex-end',
    },
    text: {
        color: colors.userCommandText,
        fontWeight: 'bold',
        textAlign: 'center',
    }
})