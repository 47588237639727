export const defaultCategories = [
    { key: 1, value: 'inventory', label: 'Inventory', icon: 'list-alt' },
    { key: 2, value: 'scheduling', label: 'Scheduling', icon: 'calendar' },
    { key: 3, value: 'qa', label: 'QA', icon: 'check-square' },
    { key: 4, value: 'r&d', label: 'R&D', icon: 'flask' },
    { key: 5, value: 'costing', label: 'Costing', icon: 'calculator' },
    { key: 6, value: 'production', label: 'Production', icon: 'cogs' },
    { key: 7, value: 'count', label: 'Count', icon: 'sort-numeric-asc' },
    { key: 8, value: 'shipping', label: 'Shipping', icon: 'ship' },
    { key: 9, value: 'forecast', label: 'Forecast', icon: 'line-chart' },
    { key: 10, value: 'order', label: 'Order', icon: 'list-alt' },
    { key: 11, value: 'receiving', label: 'Receiving', icon: 'download' },
];