import React, { Component } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { connect } from 'react-redux';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import NewIssue from "../dialogs/newIssue";
import colors from '../../constants/colors';

export default class YesNoAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      dialogVisible: false,
    };
  }

  // set initial value
  componentWillMount(nextProps, nextState) {
    this.setState({value: this.props.initial});
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({value: nextProps.initial});
    }
  }

  render() {
    const { value } = this.state;
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, width, openIssue, defaultComponent } = this.props;
    return <>
    { !width ?
      <NewIssue  
        visible={this.state.dialogVisible} 
        id={id} 
        label={label} 
        onSubmitIssue={onSubmitIssue} 
        handleClose={()=>this.setState({dialogVisible:false})} 
        width={width}
      /> : null
    }
    <View style={styles.container}>
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            if(width){
              openIssue();
            }else{
              this.setState({dialogVisible:true});
            }
          }}>
            <Text style={{color:colors.primary, fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }

        <View style={styles.wrapper}>
          <TouchableOpacity style={styles.button} onPress={() => { 
            if(!defaultComponent){
              onChange(id, 1-value); 
              this.setState({value: 1-value});
            }else{
              this.setState({ value: this.state.value })
            }
            }}>
            <View style={styles.checkBox}>
              { value
                ? <MaterialCommunityIcons
                  name='check'
                  size={20}
                  color='#9B9B9B'
                  style={styles.icon}
                />
               : null
              }
            </View>
          </TouchableOpacity>
          <Text style={styles.label}>{label}</Text>
        </View>
    </View>
    </>;
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingLeft: 55,
    // paddingRight: 10,
    // marginBottom: 10,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 34,
    borderStyle: 'solid',
    borderColor: '#AFAFAF',
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 30,
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkBox: {
    width: 22,
    height: 22,
    borderStyle: 'solid',
    borderColor: '#AFAFAF',
    borderWidth: 1,
    borderRadius: 4,
  },
  icon: {
    width: 20,
    height: 20,
  },
  label: {
    fontSize: 18,
    color: '#424242',
    fontWeight: '400',
    fontFamily: 'Roboto',
    wordBreak: 'break-all'

  },
});
