import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Index from './src/index';
import * as Font from "expo-font";
import AppLoading from 'expo-app-loading';
import "./styles.css";

let customFonts = {
  Roboto: require("./assets/fonts/Roboto-Regular.ttf"),
  "Roboto-Bold": require("./assets/fonts/Roboto-Bold.ttf"),
  "Roboto-Medium": require("./assets/fonts/Roboto-Medium.ttf"),
  "Roboto-Italic": require("./assets/fonts/Roboto-Italic.ttf"),
};

export default function App() {
  const [fontsLoaded, setFontsLoaded] = React.useState(false)
  

  const _loadFontsAsync =async () =>{
     await Font.loadAsync(customFonts);
     setFontsLoaded(true);
    
  };

  
    if (!fontsLoaded){
      //StatusBar.setBarStyle('light-content', true);
      _loadFontsAsync();
    }

    
      if (fontsLoaded) {
        return <Index />;
      } else {
        return <AppLoading />;
      }
  
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
