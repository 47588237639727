import { createAction } from "redux-actions";

import {
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_RESPONSE,
  PASSWORD_RESET_MESSAGE,
  
} from "../constants/actions";

import { resetPassword as _passwordreset } from "../api";

export function forgotpassword(router, email) {
  /**
   * The Tag definition.
   *
   * @param {Object} email - Contains the email data of the user
   */
  return (dispatch, getState) => {
    dispatch(createAction(PASSWORD_RESET_REQUEST)({ email }));
    const promise = _passwordreset(dispatch, getState, email)
      .then((response) => {
        dispatch(
          createAction(PASSWORD_RESET_MESSAGE)(
            "Please check your email for instructions to reset your password."
          )
        );
        return response;
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          createAction(PASSWORD_RESET_MESSAGE)(
            "Wrong account email or network error. Please try again"
          )
        );
      });
    dispatch(createAction(PASSWORD_RESET_RESPONSE)(true));
  };
}

