import { createAction } from '@reduxjs/toolkit'
import {
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  AUTH_TOKENS_UPDATED,
  NAVIGATE_MENU,
  LOGOUT,
  LOGIN_LOADING_REPONSE,
} from "../constants/actions";

import StorageService from "../services/storage";

import { requestLogin, checkSession as _checkSession} from "../api";
import { Alert } from "react-native";

export function login(email, password) {
  return (dispatch, getState) => {
    dispatch(createAction(LOGIN_REQUEST)({ username: email }));
    setTimeout(()=>{
      dispatch(createAction(LOGIN_LOADING_REPONSE)());
    },3000);
    dispatch(
      createAction(LOGIN_RESPONSE)(
        requestLogin(dispatch, getState, { email, password }).then(
          (response) => {
            dispatch(createAction(NAVIGATE_MENU)(response));            
              return response; 
          }
        )
      )
    );
  };
}

export function checkSession(token) {
  return (dispatch, getState) => {
    _checkSession(dispatch, getState, token).then(
      (response) => {
        dispatch(createAction(AUTH_TOKENS_UPDATED)(response));            
        return response; 
      }
    );
  };
}
export function logout() {
  return (dispatch, getState) => {
    dispatch(createAction(LOGOUT)());
  };
}

export function authTokensUpdated(data) {
  return { type: AUTH_TOKENS_UPDATED, payload: data };
}
