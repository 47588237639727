import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  InteractionManager,
} from "react-native";
import Modal from "react-native-modalbox";
import { connect } from "react-redux";
import { infoDialogClose } from "../../actions/menu";
import moment from "moment";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { filter, sortBy } from "lodash";
const columns = [
  {
    title: "Report",
    dataIndex: "report",
    width: 210,
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 140,
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
  },
];

// Mock data
const dataSource = [
  {
    report: "ABC",
    date: new Date(2017, 4, 1),
    status: "success",
  },

  {
    report: "ABC",
    date: new Date(2017, 4, 25),
    status: "pending",
  },

  {
    report: "ABC",
    date: new Date(2017, 3, 25),
    status: "failed",
  },
];

const DEFAULT_COLUMN_WIDTH = 20;

export default class SubmissionsTable extends Component {
  _renderHeader() {
    //let { columns, columnWidth } = this.props;
    return columns.map((col, index) => {
      let style = { width: col.width || DEFAULT_COLUMN_WIDTH };
      return (
        <View key={index} style={[styles.headerItem, style]}>
          <Text>{col.title}</Text>
        </View>
      );
    });
  }

  _renderCell(cellData, col) {
    let style = { width: col.width || DEFAULT_COLUMN_WIDTH };
    return (
      <View key={col.dataIndex} style={[styles.cell, style]}>
        {col.dataIndex == "date" ? (
          <Text>{moment(cellData).format("YYYY / MM / DD")}</Text>
        ) : col.dataIndex == "status" ? (
          cellData == "success" ? (
            <MaterialCommunityIcons
              name="done"
              size={24}
              color="#00aa51"
              style={styles.icon}
            />
          ) : cellData == "pending" || cellData == "started" ? (
            <ActivityIndicator />
          ) : (
            <MaterialCommunityIcons
              name="clear"
              size={24}
              color="#ED1C24"
              style={styles.icon}
            />
          )
        ) : (
          <Text>{cellData}</Text>
        )}
      </View>
    );
  }

  _renderRow(rowData, index) {
    return (
      <View key={index} style={styles.row}>
        {columns.map((col) => this._renderCell(rowData[col.dataIndex], col))}
      </View>
    );
  }

  render() {
    const dataSource = this.props.data;

    return (
      <View>
        <View style={styles.header}>{this._renderHeader()}</View>
        <ScrollView style={{ maxHeight: 430 }}>
          {sortBy(
            filter(dataSource, (o) => {
              const d1 = moment(o.date);
              const d2 = moment(new Date());
              return d2.diff(d1, "days") < 31;
            }),
            (o) => {
              return -1 * o.date;
            }
          ).map((rowData, index) => this._renderRow(rowData, index))}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
  },
  headerItem: {
    minHeight: 30,
    width: DEFAULT_COLUMN_WIDTH,
    backgroundColor: "#efefef",
    borderRightWidth: 1,
    borderRightColor: "#dfdfdf",
    alignItems: "center",
    justifyContent: "center",
  },
  cell: {
    minHeight: 25,
    width: DEFAULT_COLUMN_WIDTH,
    backgroundColor: "transparent",
    borderRightWidth: 1,
    borderRightColor: "#dfdfdf",
    alignItems: "center",
    justifyContent: "center",
  },
  row: {
    flexDirection: "row",
    backgroundColor: "#fbfbfb",
    borderBottomWidth: 1,
    borderBottomColor: "#dfdfdf",
  },
  icon: {
    height: 24,
    width: 24,
  },
});
