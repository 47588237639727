import React, { Component } from 'react'
import { Text,
         View,
         TouchableOpacity,
         FlatList,
         StyleSheet,
         Modal as ModalWeb,
        } from 'react-native';
import { connect } from 'react-redux';
import {getAllRecords, getAllTaskFromLog, setLogFilter } from '../actions/records';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { setSubmissionSelected, setIsSubmission } from "../actions/submissions";
import moment from "moment-timezone";
import { setTaskSelected } from "../actions/tasks";
import { getGroupDetail, setIsLogGroup } from "../actions/loggroups";
import colors from "../constants/colors";
import Animations from "../components/animations";
import "./styles.css";
import { viewContainer } from "./functions";
import FloatingMenu from './widgets/floatingMenu';
import { setPromptTitle } from '../actions/tasks';
import { RecordPaginator } from './recordPaginator';
import { StackActions } from '@react-navigation/native';

const recordTypes = (isLog = false) =>  ([
  {
    label: "All",
    value: "all",
  },
  ...(!isLog 
    ? [
      {
        label: "In Progress",
        value: "in_progress",
      }
    ]
    : []), 
  {
    label: "Edit Required",
    value: "edit_required",
  },
  ...(!isLog
    ? [
      {
        label:"Complete",
        value:"complete"
      }
    ]
    :[]),
  {
    label: "Approved",
    value: "approved",
  },
  ...(!isLog
    ? [
        {
          label: "Archived",
          value: "archived",
        },
      ]
    : []),
  ...(isLog
    ? [
        {
          label: "Archived",
          value: "archive",
        },
      ]
    : []),
  ...(isLog 
    ? [
        {
          label: "In Progress",
          value: "pending",
        }
      ]
    : []), 
]);
  

class Records extends Component {
    constructor(props) {
      super(props);
      this.state = {
        filter: 'all',
        filterOpen: false,
        filterLog:'all',
        filterLogOpen: false,
        showRecords: false,
        showLogs: false,
        btnName: "Show Reports",
        btnNameLog: "Show Logs Reports",
        width: window.innerWidth,
      };      
    }

    handleResize = () => {
      this.setState({width: window.innerWidth});
    }

    componentDidMount(){
        this.props.getAllRecords({page:1});
        this.props.getAllTaskFromLog({page:1});
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps, prevState){
      if(prevProps.LogFilter != null && this.props.LogFilter == null){
        this.setState({filter: 'all', filterLog: 'all'});
      }
    }

    componentWillUnmount(){
      window.removeEventListener('resize', this.handleResize);
    }
    translateStatus(name){
        switch(name){
            case "all" : return "All";
            case "in_progress" : return "In Progress";
            case "edit_required" : return "Edit Required";
            case "complete" : return "Complete";
            case "approved" : return "Approved";
            case "archived" : return "Archived";
            case "logroups" : return "Form Groups";
            case "archive" : return "Archive";
            case "pending" : return "Pending";
            default : "In Progress";            
        }
    }

    translateIcon(name){
        switch(name){
            case "all" : return "filter-variant";
            case "in_progress" : return "progress-clock";
            case "edit_required" : return "lead-pencil";
            case "complete" : return "checkbox-marked-circle";            
            case "approved" : return "checkbox-marked-circle"; 
            case "archived" :
            case "archive": 
              return "checkbox-marked-circle";    
            case "logroups" : return "apps";         
            default : return "progress-clock";
        }
    }

    getIconStyle(name){
      switch(name){
          case "in_progress" : return styles.iconInProgress;
          case "edit_required" : return styles.iconEditRequired;
          case "complete" : return styles.iconReadyForReview;
          case "approved" : return styles.iconReadyForApproval;
          case "archived" : 
          case "archive": 
            return styles.iconArchived;           
          default : return styles.iconDefault;
      }
  }

  getTimeConverted(date) {
    let d = moment.tz(date, "UTC");  
    if( moment.tz(d, moment.tz.guess(true)).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))
      return "Today at " + moment.tz(d, moment.tz.guess(true)).format("H:mm");
    else
      return moment.tz(d, moment.tz.guess(true)).format("MM/DD/YYYY");
  }

  getReportsByItemFiltered(){
    
  }
  delay(n){
    return new Promise(function(resolve){
        setTimeout(resolve,n*100);
    });
  }

 async getReportsbyItemClicked(){

    await this.delay(2);
    this.filterRecords();
}
async getLogReportsbyItemClicked(){

  await this.delay(2);
  this.filterLogRecords();
}


  filterItem(item){
    return (
      <TouchableOpacity style={item.value== this.state.filter ? styles.filterItemSelected : styles.filterItem} onPress={
        ()=> {
          this.props.setFilter(item.value);
          this.setState({filter: item.value})
          this.setState({ filterOpen: false });
          this.getReportsbyItemClicked()
        } 
        }>

        <View style={styles.iconContainer}>
          <Icon 
                  name={this.translateIcon(item.value)} 
                  style={this.getIconStyle(item.value)}
                />
        </View>
        <View style={styles.recordContainer}>
          <Text style={{padding: 10, fontSize: 15}}>{item.label}</Text>
        </View>
        
      </TouchableOpacity>
      
    );
  }

  filterLogItem(item){
    return (
      <TouchableOpacity style={item.value== this.state.filterLog ? styles.filterItemSelected : styles.filterItem} onPress={
        ()=> {
          this.props.setFilter(item.value);
          this.setState({filterLog: item.value})
          this.setState({ filterLogOpen: false })
          this.getLogReportsbyItemClicked()
        }}>

        <View style={styles.iconContainer}>
          <Icon 
                  name={this.translateIcon(item.value)} 
                  style={this.getIconStyle(item.value)}
                />
        </View>
        <View style={styles.recordContainer}>
          <Text style={{padding: 10, fontSize: 15}}>{item.label}</Text>
        </View>    
      </TouchableOpacity>
      
    );
  }

  Item(item) {          
      const createdAt = this.getTimeConverted(item.created_at);
      return (
          <TouchableOpacity 
              style={styles.item}
              onPress={() => {      
                  const chatIndex = item.components.findIndex( component => component.type == 'chatAI' );
                  if( chatIndex > -1 ){
                    const componentId = item?.components[chatIndex]?.id;
                    const category = item?.submission[componentId]?.category || null;
                    if(category){
                      this.props.setPromptTitle(item?.name);
                      setTimeout(() => {
                        this.props.navigation.dispatch(StackActions.push('PromptFormView', { initialResponse: { response: item?.submission[componentId]?.response }, completed: true }));
                      }, 200);
                      return;
                    }
                  }
                  this.props.setSubmissionSelected({ ...item });
                  this.props.setIsSubmission(true);                   
                  this.props.navigateRecord(
                    item.id,
                    item.name,
                    false,
                    item?.isPallet,
                    item?.isReceivingLog,
                    item?.isOrder,
                    item?.isRecipe,
                    item?.defaultYield,
                    item?.isInvoice,
                    item?.isShipmentLog,
                    item?.isReconciliationLog,
                    item?.isInventoryTransfer
                  );
                  this.props.setTaskSelected({});
                }}
              >
            <View style={styles.iconContainer}>
              <Icon 
                name={this.translateIcon(item.status)} 
                style={this.getIconStyle(item.status)}
              />
            </View>
            <View style={styles.recordContainer}>
                <View style={styles.itemsBody}>      
                  <View style={styles.smartBox}>    
                      <Text style={styles.recordName}>{item.name}</Text>                                         
                  </View>                    
                </View>
                <View style={styles.itemsFooter}>
                  { typeof item.owner != "undefined" &&
                    <View style={styles.smartBox}>                   
                      <Text style={styles.transactionPeer}>{item.owner.first_name + " " + item.owner.last_name}</Text>
                    </View>
                  }    
                  <View style={styles.smartBox}>  
                    <Text style={styles.recordDate}>{this.getTimeConverted(item.updated_at)}</Text>
                  </View>  
                </View>
                <Text>
                  Created at: {createdAt}
                </Text>
              </View>
          </TouchableOpacity>          
      );
    }


    TaskItem(item) {
      const createdAt = this.getTimeConverted(item.created_at);
      return (
          <TouchableOpacity 
              style={styles.item}
              onPress={() => {     
                  this.props.setTaskSelected({...item});
                  this.props.getGroupDetail(item.log_group_id, item?.sig);   
                  this.props.setIsLogGroup(true);
                  this.props.navigation.navigate('GroupView', { signature: item?.sig });            
                }}
              >
            <View style={styles.iconContainer}>
              <Icon 
                name={this.translateIcon(item.status)} 
                style={this.getIconStyle(item.status)}
              />
            </View>
            <View style={styles.recordContainer}>
                <View style={styles.itemsBody}>      
                  <View style={styles.smartBox}>    
                      <Text style={styles.recordName}>{item.name}</Text>                                         
                  </View>                    
                </View>
                <View style={styles.itemsFooter}>
                  <View style={styles.smartBox}>  
                    <Text style={styles.recordDate}>{this.getTimeConverted(item.updated_at)}</Text>
                  </View>  
                </View>
                <Text>
                  Created at: {createdAt}
                </Text>
              </View>
          </TouchableOpacity>          
      );
      
    }


    filterRecords(records){
      const {filter} = this.state;
      const { getAllRecords } = this.props;
      getAllRecords({ page:1, ...( filter != 'all' && { filter }  ) });
    }

    filterLogRecords(){
      const {filterLog} = this.state;
      const {getAllTaskFromLog} = this.props;
      switch(filterLog){
        case "all":
          getAllTaskFromLog({page: 1 });
          break;
        default:
          getAllTaskFromLog({page: 1, filter: filterLog});
          break;
      }
    }

    totalReports() {
      const {records} = this.props;
      let tasksCount = records.totalCount;
      return <View
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: colors.primary,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ color: "#fff", fontSize: 18 }}>
          Total Reports: {tasksCount}
        </Text>
      </View>
    }
  
    totalLogs() {
      const {taskLogCount} = this.props;
      return <View
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: colors.primary,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ color: "#fff", fontSize: 18 }}>
          Total Logs: {taskLogCount}
        </Text>
      </View>
    }

    render() {
        const {
          records, logRecords, tasksStatus, recordsLoading, currentPage,  pageSize, getAllRecords,
          currentLogPage, taskLogCount, logPageSize, getAllTaskFromLog 
        } = this.props; 
        const {filter, filterLog} = this.state;
        let recordsVar=records.records;  

        return (

          <View style={{ flexDirection: "row" }}>
            <FloatingMenu />
            <View style={{ flex: 2 }} />
            <View className={"viewContainer"} style={viewContainer(this.state.width, styles)} >
              <TouchableOpacity
                onPress={() => {
                  if(tasksStatus != "loading" && !recordsLoading){
                    if (recordsVar) {
                      this.setState({ showRecords: !this.state.showRecords });
                      if (this.state.showRecords) {
                        this.setState({ btnName: "Show reports" });
                      } else {
                        this.setState({ btnName: "Hide reports" });
                      }
                    }
                  }       
                }}
                style={styles.appButtonContainer}
              >
                <Text style={styles.appButtonText}>{this.state.btnName}</Text>
              </TouchableOpacity>
              {this.state.showRecords ? (
                <View>
                  <TouchableOpacity onPress={() => this.setState({ filterOpen: true })} style={{ alignItems: 'center' }}>
                    <View style={{ flexDirection: "row", padding: 5 }}>
                      <Icon name={this.translateIcon(this.state.filter)} style={this.getIconStyle(this.state.filter)} />
                      <Text style={{ alignSelf: 'center', fontSize: 15, fontWeight: '600' }}>{this.translateStatus(this.state.filter)}</Text>
                    </View>
                  </TouchableOpacity>
                  <View style={styles.centeredView}>
                    <ModalWeb
                      animationType="none"
                      transparent={true}
                      visible={this.state.filterOpen}>

                      <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                          <Icon onPress={() => {
                            this.setState({ filterOpen: false });
                            this.filterRecords(); 
                          }} name="close" style={styles.filterClose} />
                          <Text style={{ marginBottom: 10, fontWeight: '500' }}>Please select a filter:</Text>
                          <FlatList
                            data={recordTypes()}
                            renderItem={({ item }) => this.filterItem(item)}
                            keyExtractor={item => item.value}
                            onPress={() => {
                              this.setState({ filterOpen: false });
                              this.filterRecords(); 
                            }} 

                          />
                        </View>
                      </View>
                    </ModalWeb>

                  </View>
                  {recordsVar && !recordsLoading && recordsVar.length > 0 ?
                    <FlatList
                      data={recordsVar}
                      renderItem={({ item }) => this.Item(item)}
                      keyExtractor={item => item._id.toString()}
                    /> : null
                  }
                  {
                    this.props.records.records.length > 0 
                    ? <RecordPaginator currentPage={currentPage} filter={filter} pageSize={pageSize} tasksCount={records?.totalCount} navigationHandler={getAllRecords}/>
                    : <Text style={{ textAlign: 'center' }}>No reports found</Text>
                  }
                  {this.props.records.records.length > 0 && this.totalReports()}
                </View>
              ) : null}
              <Separator />
              <TouchableOpacity
                onPress={() => {
                  if(tasksStatus != "loading" && ! recordsLoading){
                    this.setState({ showLogs: !this.state.showLogs });
                    if (this.state.showLogs) {
                      this.setState({ btnNameLog: "Show Log reports" });
                    } else {
                      this.setState({ btnNameLog: "Hide Log reports" });
                    }
                  }
                }}
                style={styles.appButtonContainer}
              >
                <Text style={styles.appButtonText}>{this.state.btnNameLog}</Text>
              </TouchableOpacity>
              {this.state.showLogs ? (
                <View>
                  <TouchableOpacity onPress={() => this.setState({ filterLogOpen: true })} style={{ alignItems: 'center' }}>
                    <View style={{ flexDirection: "row", padding: 5 }}>
                      <Icon name={this.translateIcon(this.state.filterLog)} style={this.getIconStyle(this.state.filterLog)} />
                      <Text style={{ alignSelf: 'center', fontSize: 15, fontWeight: '600' }}>{this.translateStatus(this.state.filterLog)}</Text>
                      <Text style={{ alignSelf: 'center', fontSize: 15, fontWeight: '600' }}> Log Reports</Text>
                    </View>
                  </TouchableOpacity>
                  <View style={styles.centeredView}>
                    <ModalWeb
                      animationType="none"
                      transparent={true}
                      visible={this.state.filterLogOpen}>
                      <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                          <Icon onPress={() => {
                            this.setState({ filterLogOpen: false })
                            this.filterLogRecords();
                          }} name="close" style={styles.filterClose} />
                          <Text style={{ marginBottom: 10, fontWeight: '500' }}>Please select a filter:</Text>
                          <FlatList
                            data={recordTypes(true)}
                            renderItem={({ item }) => this.filterLogItem(item)}
                            keyExtractor={item => item.value}
                          />
                        </View>
                      </View>
                    </ModalWeb>

                  </View>
                  { tasksStatus !== "loading" && !recordsLoading ?
                    <View>
                      <FlatList
                            data={logRecords}
                            renderItem={({ item }) => this.TaskItem(item) }
                            keyExtractor={item => item._id.toString()}
                        />
                        { 
                          logRecords?.length > 0 
                          ? <RecordPaginator resultsPerPage={5} currentPage={currentLogPage} filter={filterLog} pageSize={logPageSize} tasksCount={taskLogCount} navigationHandler={getAllTaskFromLog}/>
                          : <Text style={{textAlign: 'center'}}>No log reports found</Text>}
                        { logRecords?.length > 0 && this.totalLogs() }
                    </View>
                    :
                    <View style={styles.centeredView}>
                      <Animations animationfile='loading-dots-gray' />
                    </View>
                  }

                </View>
              ) : null}
            </View>
            <View style={{ flex: 2 }} />
          </View>

        )
        
  }
}

const mapStateToProps = (state) => {
    return {
      records: state.records.get('records'),
      logRecords: state.records.get('logRecords'), 
      recordsLoading:state.records.get('recordsLoading'),
      tasksStatus: state.tasks.get('status'),
      currentPage: state.tasks.get('currentPage'),
      tasksCount: state.tasks.get('tasksCount'),
      pageSize: state.tasks.get('pageSize'),
      currentLogPage: state.tasks.get('currentLogPage'),
      taskLogCount: state.tasks.get('taskLogCount'),
      logPageSize : state.tasks.get('logPageSize'),  
      taskLogTotal : state.tasks.get('taskLogTotal'),
      LogFilter: state.records.get('LogFilter'),
    };
};

const Separator = () => (
  <View style={styles.separator} />
);

export default connect(mapStateToProps, (dispatch, props) => {
    return {
      getAllRecords: (params) => {
        dispatch(getAllRecords(params));
      },  
      getAllTaskFromLog: (params) => {
        dispatch(getAllTaskFromLog(params));
      },    
      setSubmissionSelected: (value) =>{
        dispatch(setSubmissionSelected(value));
      },
      setIsSubmission: (value) =>{
        dispatch(setIsSubmission(value));
      },
      navigateRecord: (id, name, newReport, isPallet, isReceivingLog, isOrder, isRecipe, defaultYield, isInvoice, isShipmentLog, isReconciliationLog, isInventoryTransfer) =>{
        props.navigation.navigate('FormView', { formId: id, title: name, newReport:newReport, submission: null, task_id: null, log_group_id:null, isPallet: isPallet, isReceivingLog: isReceivingLog, isOrder: isOrder, isRecipe: isRecipe, defaultYield: defaultYield, isInvoice: isInvoice, isShipmentLog: isShipmentLog, isReconciliationLog: isReconciliationLog, isInventoryTransfer: isInventoryTransfer});
      },
      setTaskSelected: (value) => {
        dispatch(setTaskSelected(value));
      },
      getGroupDetail: (id, sig) => {
        dispatch(getGroupDetail(id, sig));
      },
      setIsLogGroup: (val) => {
        dispatch(setIsLogGroup(val))
      },
      setFilter: (val) =>{
        dispatch(setLogFilter(val));
      },
      setPromptTitle: (val) =>{
        dispatch(setPromptTitle(val));
      }, 
    };
})(Records);

const styles = StyleSheet.create({    
    filterItem: {
        flexGrow: 1,
        flexDirection: "row",
        backgroundColor: colors.filter,   
        borderRadius: 5,  
        marginBottom: 2,        
        marginHorizontal: 2,
        borderColor: "lightgray",
        borderWidth: 1,
        marginBottom: 2,       
    },  
    filterItemSelected: {
      flexGrow: 1,
      flexDirection: "row",
      backgroundColor: "#F5F5F5",         
      borderRadius: 5,  
      marginBottom: 2,        
      marginHorizontal: 2,
      borderColor: "lightgray",
      borderWidth: 2,
      marginBottom: 2,       
  },
    item: {
        flexDirection: "row",
        backgroundColor: 'lightgray',
        backgroundColor: '#fff',   
        borderRadius: 5,  
        marginBottom: 2,        
        marginHorizontal: 2,
    },
    itemsBody: {
        flexDirection: "row",
        backgroundColor: 'lightgray',                    
        backgroundColor: '#fff',        
        flexGrow: 1,        
    },
    itemsFooter: {
        flexDirection: "row",
        backgroundColor: 'lightgray',              
        backgroundColor: '#fff',     
    },
    smartBox: {
        flexGrow: 1,
        justifyContent: "center",
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",                 
    },
    modalView: {
      height: 370,      
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5
    },
    iconContainer: {         
        justifyContent: "center",
    },  
    recordContainer: {
        flexGrow: 1, 
        
        justifyContent: "center",
    },       
    iconInProgress: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: colors.in_progress,
      
    },
    iconDefault:{
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: "#7B7F7F",
    },
    filterClose: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgray',
      position: 'absolute',
      top: 5,
      right: 0,
    
    },
    iconEditRequired: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: colors.edit_required,
    },
    iconReadyForReview: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: colors.complete,
    },
    iconReadyForApproval: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: colors.approved,
    },
    iconApproved: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgreen',
    },   
    iconArchived: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: colors.archived,
    },
    recordName: {      
        fontSize: 15,
        marginTop: 10,
        marginRight:2,
        fontWeight: "bold",
        textAlign: "left",
        alignSelf: "flex-start",
    },   
    transactionPeer: {      
        fontSize: 18,
        marginTop: 1, 
        color: "gray",    
        alignSelf: "flex-start",
    },
    recordDate: {
        fontSize: 18,
        marginTop: 1,     
        color: "gray",
        alignSelf: "flex-end",
    },
    topBar: {
      paddingVertical: 20,
      backgroundColor: colors.primary,
      justifyContent: "center",
      alignItems: "center",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowRadius: 5,
      shadowOpacity: 0.2,
      shadowColor: "black",
      zIndex: 10,
    },
    appButtonContainer: {
      elevation: 8,
      backgroundColor: "#cfd8dc",
      paddingVertical: 16,
      paddingHorizontal: 12,
    },
    appButtonText: {
      fontSize: 14,
      color: colors.primary,
      fontWeight: "bold",
      alignSelf: "center",
      textTransform: "uppercase",
    },
    separator: {
      marginVertical: 20,
      borderBottomColor: colors.primary,
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
    viewContainerMax: {
      flex: 2, 
      backgroundColor: '#fff', 
      borderRadius: 20, 
      marginTop: 10,
      minWidth: 600,
      paddingTop: 50,
      paddingLeft: 50,
      paddingRight: 50,
    },
    viewContainerMax1: {
      flex: 2, 
      backgroundColor: '#fff', 
      borderRadius: 20, 
      marginTop: 10,
      minWidth: 450,
      paddingTop: 20,
      paddingLeft: 15,
      paddingRight: 15,
    },
    viewContainerMax2: {
      flex: 2, 
      backgroundColor: '#fff', 
      borderRadius: 20, 
      marginTop: 10,
      minWidth: 350,
      paddingTop: 50,
      paddingLeft: 10,
      paddingRight: 10,
    },
    viewContainerMax3: {
      flex: 2, 
      backgroundColor: '#fff', 
      borderRadius: 20, 
      marginTop: 10,
      minWidth: 300,
      paddingTop: 50,
      paddingLeft: 10,
      paddingRight: 10,
    },
});