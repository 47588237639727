import React, { Component } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { connect } from 'react-redux';
import CheckBox from '../widgets/checkbox';
import NewIssue from "../dialogs/newIssue";
import colors from '../../constants/colors';

export default class YesNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      dialogVisible: false,
    };
  }

  // set initial value
  componentWillMount(nextProps, nextState) {
    this.setState({value: this.props.initial});
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({value: nextProps.initial});
    }
  }

  render() {
    const { value, dialogVisible } = this.state;
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder, width, openIssue, defaultComponent } = this.props;
    return <>    
    <View style={label.length<50 && label.length>15? {...styles.container, height: !width && dialogVisible ? 200 : 'auto' }: {...styles.container2, height: !width && dialogVisible ? 200 : 'auto'}}>
    <CheckBox login={false} defaultComponent={defaultComponent} initial={this.props.initial} value={this.state.value} onPress={(val) => { console.log('CHECKBOX value: ' + val); 
        this.setState({value: val}); 
        onChange(id, val); 
    }} /> 
    <Text style={styles.label}>{label+" "}<Text style={{fontStyle: 'italic',color: colors.primary,fontSize: 20, wordBreak: 'break-all'}}>{placeholder}</Text></Text>
    
      {
        (isIssuable && isCompleted) && 
        <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => { 
          if(width){
            openIssue();
          }else{
            this.setState({dialogVisible:true});
          }
        }}>
          <Text style={{color:colors.primary, fontWeight:'bold', marginTop: 5}}>Issue</Text>  
        </TouchableOpacity>
      }

    </View>
        { !width ?
          <NewIssue  
            visible={this.state.dialogVisible} 
            id={id} 
            label={label} 
            onSubmitIssue={onSubmitIssue} 
            handleClose={()=>this.setState({dialogVisible:false})} 
            width={width}
          /> : null
        }
    </>;
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: "row",
    // paddingLeft: 15,
    // paddingRight: 10,
    // paddingBottom:15,
    paddingTop:5,
  },
  container2: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: "row",
    // paddingLeft: 15,
    // paddingRight: 10,
    // paddingBottom:25,
    // paddingTop:25,
  },
  container3: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: "row",
    paddingLeft: 15,
    paddingRight: 10,
    paddingBottom:15,
    paddingTop:15,
  },
  label: {
    marginTop: 5,
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
