import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import colors from "../../constants/colors";
import PropTypes from "prop-types";
class Chip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      background: "white",
    };
  }
  componentDidMount() {
    let background = "";
    switch (this.props.type) {
      case "success":
        background = colors.success;
        break;
      case "primary":
        background = colors.primary;
        break;
      case "error":
        background = colors.error;
        break;
      case "info":
        background = colors.gray;
        break;
      case "orange":
        background = colors.access;
        break;
      case "ambar":
        background = colors.ambar;
        break;
      case "accent":
        background = colors.accent;
        break;
      default:
        background = colors.blue_gray;
    }
    this.setState({
      background: background,
    });
  }
  capitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  render() {
    return (
      <View
        style={[
          styles.container,
          {
            maxWidth: (this.props.label.length + 3) * 10,
            backgroundColor: colors.gray_darken_2,
          },
        ]}
      >
        <Text style={styles.chipText}>{this.capitalize(this.props.label)}</Text>
      </View>
    );
  }
}
export default Chip;

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 4,
    marginBottom: 10,
    marginTop: 10,
    marginRight: 15,
    paddingBottom: 5,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 5,
  },
  chipText: {
    color: colors.primary,
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "center",
  },
});
