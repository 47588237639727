import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import { 
     INVALID_REGISTER_HIDE,
     SET_REGISTER_LOADING,
} from '../constants/actions';

import StorageService from '../services/storage';

const initialState = {
    isRegisterCompleted: false,
    registerLoading: false,
};

export default handleActions({
  [INVALID_REGISTER_HIDE]: (state, action) => {
    console.log("ISREGISTER "+JSON.stringify(action.payload))
    return state.set('isRegisterCompleted', action.payload);
    
  },
  [SET_REGISTER_LOADING]: (state, action) => {
    return state.set('registerLoading', action.payload);    
  },
}, Immutable.fromJS(initialState));