import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#CDD7DC',
    flex: 1,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: "#0453A9"
  },
  textInput: {
    backgroundColor: '#F8F8F8',
    color: '#A1A1A1',
    margin: 30,
    padding: 10,
    flex: 0.9,
    borderRadius: 12,
  },
  attachments:{
    flexDirection: 'column',
    alignItems: 'center',
  }
});

export default styles;
